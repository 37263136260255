<template>
  <b-field :label="label" custom-class="my-input-field-label secondary-font">
    <b-input
      @input="onInput"
      custom-class="my-input-field"
      :type="'float'"
      :disabled="disabled"
      :placeholder="placeholder"
    ></b-input>
  </b-field>
</template>

<script>
export default {
  name: "MyInputField",
  props: ["label", "placeholder", "disabled", "type"],
  methods: {
    onInput(evt) {
      this.$emit("input", evt);
    },
  },
};
</script>

<style>
.my-input-field-label {
  text-align: left;
  font-size: 12px;
  color: var(--my-grey);
}

.my-input-field {
  background: #121212;
  border: none;
  color: white;
}
.my-input-field:disabled {
  background: #121212;
  border: none;
  color: white;
}

@media screen and (max-width: 768px) {
  .my-input-field-label {
    text-align: center;
  }
}
</style>