var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.art.tokenId === undefined)?_c('div',{staticClass:"column",staticStyle:{"padding":"45vh 0","text-align":"center"}},[_vm._v(" Obtaining art informations,"),_c('br'),_vm._v(" please wait.. ")]):_vm._e(),(_vm.art.tokenId !== undefined)?_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column artist"},[_c('div',[_c('LazyComponent',[_c('div',{staticStyle:{"position":"relative"}},[(_vm.art.isVideo)?_c('video',{staticClass:"video-home",staticStyle:{"width":"100%","cursor":"zoom-in"},attrs:{"preload":"auto","loop":"","autoplay":"","defaultMuted":"","playsinline":""},domProps:{"muted":_vm.videoIsMuted},on:{"click":function($event){return _vm.initFullscreenVideo(_vm.art.metadata.fullscreenUri)}}},[_c('source',{attrs:{"src":_vm.art.metadata.artifactUri}})]):_vm._e(),(_vm.art.hasAudio !== undefined && _vm.art.hasAudio === true)?_c('div',{staticClass:"video-audio",class:{ 'force-bottom': _vm.showFullVideo },on:{"click":_vm.toggleMuted}},[(!_vm.videoIsMuted)?_c('i',{staticClass:"mdi mdi-volume-high"}):_vm._e(),(_vm.videoIsMuted)?_c('i',{staticClass:"mdi mdi-volume-off"}):_vm._e()]):_vm._e()]),_c('Loader',{attrs:{"slot":"placeholder"},slot:"placeholder"})],1)],1),(!_vm.art.isVideo && !_vm.art.isHtml)?_c('div',{staticClass:"nft-image",staticStyle:{"cursor":"zoom-in"},on:{"click":function($event){return _vm.initFullscreen(_vm.art.metadata.fullscreenUri)}}},[_c('LazyComponent',[_c('img',{attrs:{"src":_vm.art.metadata.artifactUri.replace(
                'ipfs://',
                'https://ipfs.yomi.digital/ipfs/'
              ),"alt":_vm.art.metadata.name,"transition-duration":"50","width":"100%"}}),_c('Loader',{attrs:{"slot":"placeholder"},slot:"placeholder"})],1)],1):_vm._e(),(_vm.art.isHtml)?_c('div',[_c('iframe',{staticStyle:{"width":"100%","height":"90vh"},attrs:{"allowfullscreen":"","src":_vm.art.metadata.artifactUri
              .replace(
                'https://cdn.fakewhale.xyz/',
                'https://ipfs.yomi.digital/ipfs/'
              )
              .replace('.jpg', '')}})]):_vm._e(),_c('div',{staticClass:"links-wrapper"},[_c('h1',{staticClass:"title is-3 secondary-font"},[_vm._v(_vm._s(_vm.art.metadata.name))]),_c('a',{attrs:{"href":'/#/artist/' + _vm.art.from.address}},[_vm._v(_vm._s(_vm.art.from.alias))]),_c('br'),_vm._v(" BLOCK #"),_c('a',{attrs:{"href":_vm.explorerEndpoint.replace('{block}', _vm.art.level),"target":"_blank"}},[_vm._v(_vm._s(_vm.art.level))]),_c('h1',{staticClass:"title is-5"},[_vm._v("NOT FOR SALE")])])])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"description",staticStyle:{"text-align":"center"},domProps:{"innerHTML":_vm._s(_vm.art.metadata.description)}})])]),_vm._m(0)])]):_vm._e(),(_vm.showFull)?_c('div',{staticClass:"fullscreen",style:('background-image:url(' + _vm.fullscreenImg + '); background-size: contain;'),on:{"click":function($event){return _vm.hideFullScreenImage()}}}):_vm._e(),(_vm.showFullVideo)?_c('div',{staticClass:"fullscreen",staticStyle:{"background-color":"#000","color":"#fff"},on:{"click":function($event){return _vm.hideFullScreenVideo()}}},[_c('video',{staticStyle:{"height":"100vh","width":"100%"},attrs:{"autoplay":"","loop":"","playsinline":""},domProps:{"muted":_vm.videoIsMuted}},[_c('source',{attrs:{"src":_vm.fullscreenVideo,"type":"video/mp4"}}),_vm._v(" Your browser does not support HTML video. ")])]):_vm._e(),_c('div',{staticStyle:{"position":"fixed","top":"0","left":"0","width":"100%","height":"100%","background":"#000","z-index":"99"},attrs:{"id":"black"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"100px"}},[_c('a',{staticClass:"item-modal-back-btn secondary-text secondary-font read m-auto",staticStyle:{"color":"#fff"},attrs:{"href":"/"}},[_vm._v("Back to vault")])])
}]

export { render, staticRenderFns }