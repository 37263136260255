<template>
  <b-collapse
    v-if="!isMobile"
    class="card history-collapse"
    animation="slide"
    aria-id="contentIdForA11y3"
  >
    <template #trigger="props">
      <div
        class="card-header history-collapse-header"
        role="button"
        aria-controls="contentIdForA11y3"
        :aria-expanded="props.open"
      >
        <p class="card-header-title history-collapse-title pl-5">History</p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
        </a>
      </div>
    </template>

    <div class="card-content">
      <div class="content">
        <div class="rows">
          <div class="row is-12">
            <b-field
              label="Event type"
              custom-class="history-select-field secondary-font"
            >
              <b-select
                @input="onSelectInput"
                v-model="selectedFilter"
                expanded
                class="history-select"
              >
                <option :value="''">---</option>
                <option
                  v-for="(option, index) in transactionsOptions"
                  :value="option"
                  :key="index"
                >
                  {{ option }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="row is-12 mt-6 px-2">
            <div class="rows history-list-ctn">
              <div
                v-for="(transaction, index) in filteredTransactions"
                :key="index"
                class="row is-12 history-table-tr"
              >
                <div class="columns">
                  <div
                    class="has-text-left-tablet column is-4 history-table-col"
                  >
                    {{ transaction.date }}
                  </div>
                  <!-- <div class="column is-1 history-table-col">
                    {{ transaction.image }}
                  </div> -->
                  <div
                    class="column is-2 has-text-left-tablet history-table-col"
                  >
                    {{
                      transaction.event_type !== null
                        ? getHistoryEventLabel(transaction.event_type)
                        : getHistoryEventLabel(
                            transaction.marketplace_event_type
                          )
                    }}
                  </div>
                  <div class="column is-2 history-table-col">
                    {{
                      transaction.price_xtz != null
                        ? transaction.price_xtz + " xtz"
                        : "---"
                    }}
                  </div>
                  <div
                    class="has-text-right-tablet column is-4 history-table-col"
                  >
                    <span v-if="transaction.recipient != null">{{
                      transaction.recipient.alias !== null
                        ? transaction.recipient.alias
                        : transaction.recipient.address
                    }}</span>
                    <span v-else-if="transaction.creator != null">{{
                      transaction.creator.alias !== null
                        ? transaction.creator.alias
                        : transaction.creator.address
                    }}</span>
                  </div>
                </div>
                <!-- <div class="is-hidden-tablet">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Date</th>
                        <td class="has-text-left">{{ transaction.date }}</td>
                      </tr>
                      <tr>
                        <th>Event</th>
                        <td class="has-text-left">
                          {{ transaction.event_type }}
                        </td>
                      </tr>
                      <tr>
                        <th>Amount</th>
                        <td class="has-text-left">{{ transaction.amount }}</td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td class="has-text-left">
                          {{ transaction.creator_address }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-collapse>
  <div v-else class="card history-collapse-mobile">
    <div
      class="card-header history-collapse-header"
      role="button"
      aria-controls="contentIdForA11y3"
    >
      <p class="card-header-title history-collapse-title">History</p>
    </div>

    <div class="card-content">
      <div class="content">
        <div class="rows">
          <div class="row is-12">
            <b-field
              label="Event type"
              custom-class="history-select-field secondary-font"
            >
              <b-select
                @input="onSelectInput"
                v-model="selectedFilter"
                expanded
                class="history-select"
              >
                <option :value="''">---</option>
                <option
                  v-for="(option, index) in transactionsOptions"
                  :value="option"
                  :key="index"
                >
                  {{ option }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div
            class="row is-12 mt-6 history-table-ctn scrollbar-semi-transparent"
          >
            <table class="table">
              <thead>
                <tr class="secondary-font has-text-left">
                  <th class="history-table-mobile-th">Date</th>
                  <th class="history-table-mobile-th">Event</th>
                  <th class="history-table-mobile-th">Price</th>
                  <th class="history-table-mobile-th">Address</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(transaction, index) in filteredTransactions"
                  :key="index"
                  class="row is-12 history-table-tr"
                >
                  <td class="has-text-left history-table-mobile-cell">
                    {{ transaction.date }}
                  </td>
                  <td class="has-text-left history-table-mobile-cell">
                    {{
                      transaction.event_type !== null
                        ? getHistoryEventLabel(transaction.event_type)
                        : getHistoryEventLabel(
                            transaction.marketplace_event_type
                          )
                    }}
                  </td>
                  <td class="has-text-left history-table-mobile-cell">
                    {{
                      transaction.price_xtz != null
                        ? transaction.price_xtz + " xtz"
                        : "---"
                    }}
                  </td>
                  <td class="has-text-left history-table-mobile-cell">
                    <span v-if="transaction.recipient != null">{{
                      transaction.recipient.alias !== null
                        ? transaction.recipient.alias
                        : transaction.recipient.address
                    }}</span>
                    <span v-else-if="transaction.creator != null">{{
                      transaction.creator.alias !== null
                        ? transaction.creator.alias
                        : transaction.creator.address
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="rows"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkViewport from "../mixins/checkViewport.js";

const labelMap = {
  transfer: "Transfer",
  list_create: "List",
  list_buy: "Sale",
  offer_accept: "Accept offer",
  offer_create: "Offer",
  mint: "Mint",
};

export default {
  name: "History",
  mixins: [checkViewport],
  props: ["transactions"],
  data() {
    return {
      selectedFilter: "",
    };
  },
  computed: {
    transactionsOptions() {
      const options = [];

      if (this.transactions !== null && this.transactions != undefined) {
        for (let i = 0; i < this.transactions.length; i++) {
          const transaction = this.transactions[i];
          if (transaction.event_type !== null) {
            if (!options.includes(transaction.event_type.toLowerCase()))
              options.push(transaction.event_type.toLowerCase());
          } else if (transaction.marketplace_event_type !== null) {
            if (
              !options.includes(
                transaction.marketplace_event_type.toLowerCase()
              )
            )
              options.push(transaction.marketplace_event_type.toLowerCase());
          }
        }
      }

      return options;
    },
    filteredTransactions() {
      if (this.selectedFilter == "") {
        return this.transactions;
      }

      return this.transactions.filter(
        (tr) =>
          tr?.event_type?.toLowerCase() == this.selectedFilter ||
          tr?.marketplace_event_type?.toLowerCase() == this.selectedFilter
      );
    },
  },
  methods: {
    onSelectInput(val) {
      // console.log(val);
    },
    getHistoryEventLabel(event_type) {
      if (labelMap[event_type] != undefined) return labelMap[event_type];

      return event_type;
    },
  },
};
</script>

<style>
.history-collapse {
  background: black;
  border: 1px solid var(--my-grey);
}

.mdi-24px.mdi-set,
.mdi-24px.mdi:before {
  border: 3px solid transparent;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.425em;
  pointer-events: none;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 0.425em;
  border-color: var(--my-grey);
  border-width: 2px;
}

.navbar-link:not(.is-arrowless)::after,
.select:not(.is-multiple):not(.is-loading)::after {
  border-width: 2px;
}

.history-list-ctn {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}
.history-list-ctn::-webkit-scrollbar {
  width: 5px;
}

.history-list-ctn::-webkit-scrollbar-track {
  background: rgba(114, 109, 114, 0.361);
  border-radius: 5px;
}

.history-list-ctn::-webkit-scrollbar-thumb {
  background: #121212;
  border-radius: 5px;
  transform: rotate(-180deg);
}

.history-collapse-mobile {
  background: black;
}

.history-collapse-header {
  border-bottom: 1px solid #121212;
  color: var(--my-grey);
}

.history-collapse-title {
  color: white;
  font-weight: 400;
}

.history-table-tr {
  color: white;
}

.history-table-ctn {
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 600px;
}
.history-table-ctn table {
  background: black;
}
.history-table-ctn table tr,
.history-table-ctn table td,
.history-table-ctn table th {
  border: none;
}

.history-table-col {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .history-table-tr {
    border-bottom: 1px solid var(--my-grey);
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
  }

  .history-table-mobile-cell,
  .history-table-mobile-th {
    padding-right: 30px !important;
    padding-bottom: 20px !important;
  }
}

.history-select-field {
  text-align: left;
  color: var(--my-grey);
  font-family: monospace, "DM Mono" ;
  font-size: 14px;
  font-weight: 300;
}

.history-select select {
  background: #121212;
  border: none;
  color: white;
}

.history-select .select:not(.is-multiple):not(.is-loading)::after {
  border-color: var(--my-grey);
}
</style>
