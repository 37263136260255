<template>
  <div v-if="!isMobile" class="footer secondary-font">
    <div class="mobile-margin-top">
      Fakewhale {{ new Date().getFullYear() }} ©
      <a
        href="https://yomi.digital/"
        target="_blank"
        class="desktop-margin-left is-block-mobile mobile-margin-top"
        >Developed w/ love by YOMI</a
      >
    </div>
    <div class="footer-right">
      <a
        href="https://www.iubenda.com/privacy-policy/79683827"
        target="_blank"
        class="is-block-mobile mobile-margin-top"
        >Privacy Policy</a
      >
      <span class="horizontal-spacer">|</span>
      <a
        class="is-block-mobile mobile-margin-top"
        href="https://www.iubenda.com/privacy-policy/79683827/cookie-policy"
        target="_blank"
        >Cookie Policy</a
      >
      <span class="horizontal-spacer">|</span>
      <a href="/#/faq" class="is-block-mobile mobile-margin-top">FAQ</a>
      <span class="horizontal-spacer">|</span>
      <a
        href="https://social.fakewhale.xyz/links"
        class="is-block-mobile mobile-margin-top"
        target="_blank"
        >Links</a
      >
      <span class="horizontal-spacer">|</span>
      <a
        class="is-block-mobile mobile-margin-top"
        href="https://twitter.com/fakewhale_xyz"
        target="_blank"
        >Twitter</a
      >
    </div>
  </div>
  <div v-else class="footer-mobile">
    <span class="secondary-font pt-4">
      Fakewhale {{ new Date().getFullYear() }} ©
    </span>

    <div>
      <div>
        <a
          href="https://www.iubenda.com/privacy-policy/79683827"
          target="_blank"
          >Privacy Policy</a
        >
        <span class="horizontal-spacer">|</span>
        <a
          href="https://www.iubenda.com/privacy-policy/79683827/cookie-policy"
          target="_blank"
          >Cookie Policy</a
        >
      </div>
      <div>
        <a href="/#/faq">FAQ</a>
        <span class="horizontal-spacer">|</span>
        <a href="https://social.fakewhale.xyz/links">Links</a>
        <span class="horizontal-spacer">|</span>
        <a href="https://twitter.com/fakewhale_xyz" target="_blank">Twitter</a>
      </div>
    </div>
    <a href="https://yomi.digital/" target="_blank"
      >Developed w/ love by YOMI</a
    >
    <icon-button @iconClicked="ScrollToTop" class="footer-up-btn"
      ><img src="/arrow.svg"
    /></icon-button>
  </div>
</template>

<script>
import IconButton from "../components/IconButton.vue";
import checkViewport from "../mixins/checkViewport.js";
export default {
  name: "MyFooter",
  mixins: [checkViewport],
  components: {
    IconButton,
  },
  methods: {
    ScrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.footer {
  position: fixed;
  width: 100%;
  height: 80px;
  padding: 0 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  z-index: 99;
  background-color: #000 !important;
  left: 0;
  border-top: 1px solid #333333;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}

.footer-mobile {
  display: flex;
  height: auto;
  font-size: 12px;
  padding-top: 5px;
  line-height: 20px;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px !important;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  z-index: 99;
  background-color: #000 !important;
  left: 0;
  border-top: 1px solid #333333;
  text-align: left;
  font-weight: 400;
  gap: 10px;
}

.footer a,
.footer-mobile a {
  border: none;
}

.footer-right {
  text-align: right;
  float: right;
}

.desktop-margin-left {
  margin-left: 30px;
}

.horizontal-spacer {
  margin: 0 10px;
}

.footer-up-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.footer-up-btn img {
  transform: rotate(-90deg);
}

@media screen and (max-width: 768px) {
  .footer {
    height: auto;
    font-size: 12px;
    padding-top: 5px;
    line-height: 20px;
    position: relative;
    flex-direction: column;
  }

  .footer-right {
    text-align: center;
    float: none;
  }

  .desktop-margin-left {
    margin-left: 0px;
  }
  .mobile-margin-top {
    margin-top: 10px;
  }
}
</style>
