<template>
  <button
    class="my-button secondary-font"
    :class="disabled ? 'my-button-disabled' : ''"
    :disabled="disabled"
    @click="onButtonClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "MyButton",
  props: ["disabled"],
  methods: {
    onButtonClick() {
      this.$emit("clicked");
    },
  },
};
</script>

<style>
.my-button {
  background: none;
  border: 1px solid var(--my-grey);
  border-radius: 4px;
  color: var(--my-grey);
  font-size: 13px;
  font-weight: 600;
  height: 36px;
  min-width: 140px;
  transition: all 0.3s ease-in-out;
}
.my-button:not(.my-button-disabled):hover {
  background-color: white;
  border-radius: 50px;
  border-color: transparent;
  cursor: pointer;
  color: black;
}

.my-button.my-button-disabled {
  opacity: 0.5;
}
</style>