var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-modal-root"},[_c('div',{staticClass:"rows"},[_c('div',{staticClass:"row mb-3"},[_c('button',{staticClass:"item-modal-back-btn secondary-text secondary-font read",on:{"click":_vm.onBackClick}},[_vm._v(" Back ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column pl-2 pr-5 py-6"},[(_vm.item.mime.startsWith('audio'))?_c('div',{staticClass:"item-modal-img-box is-relative"},[_c('img',{staticClass:"item-modal-img",staticStyle:{"width":"75%"},attrs:{"src":_vm.item.display_uri.replace(
                  'ipfs://',
                  'https://ipfs.yomi.digital/ipfs/'
                )},on:{"click":_vm.onFocusIn}}),_c('div',{staticClass:"item-modal-img-filter secondary-font"}),(!_vm.isPlay)?_c('img',{staticClass:"audio-control",attrs:{"src":require("../assets/sound_max_fill.svg"),"alt":""},on:{"click":_vm.playAudio}}):_vm._e(),(_vm.isPlay)?_c('img',{staticClass:"audio-control",attrs:{"src":require("../assets/sound_mute_fill.svg"),"alt":""},on:{"click":_vm.playAudio}}):_vm._e(),_c('audio',{ref:"audioPlayer",attrs:{"src":_vm.item.artifact_uri.replace(
                  'ipfs://',
                  'https://ipfs.yomi.digital/ipfs/'
                )}})]):(!_vm.item.mime.startsWith('video'))?_c('div',{staticClass:"item-modal-img-box"},[_c('img',{staticClass:"item-modal-img",attrs:{"src":_vm.item.artifact_uri.replace(
                  'ipfs://',
                  'https://ipfs.yomi.digital/ipfs/'
                )},on:{"click":_vm.onFocusIn}}),_c('div',{staticClass:"item-modal-img-filter secondary-font"})]):_c('div',{staticClass:"item-modal-video-box"},[_c('video',{staticClass:"item-modal-video",attrs:{"preload":"auto","loop":"","autoplay":"","muted":"","defaultMuted":"","playsinline":""},domProps:{"muted":true},on:{"click":_vm.onFocusIn}},[_c('source',{attrs:{"src":_vm.item.artifact_uri.replace(
                    'ipfs://',
                    'https://ipfs.yomi.digital/ipfs/'
                  )}})]),_c('div',{staticClass:"item-modal-img-filter secondary-font"})])]),_c('div',{staticClass:"column pt-6"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-auto pt-3 has-text-left"},[_c('a',{staticClass:"item-modal-artist artist-name mb-3 is-inline-block",attrs:{"href":'/#/artist/' + this.addressCreator}},[_vm._v(" "+_vm._s(_vm.item.artistName)+" ")]),_c('p',{staticClass:"item-modal-sub secondary-font",class:!_vm.isMobile ? 'mb-6' : 'mb-2'},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),(_vm.isMobile)?_c('div',{staticClass:"item-modal-objkt-mobile has-text-left-mobile mb-5"},[_c('a',{attrs:{"href":_vm.item.objktUrl,"target":"_blank"}},[_vm._v("View on Objkt")])]):_vm._e(),(
                  !_vm.isMobile ||
                  _vm.item.description.length < 100 ||
                  _vm.descriptionOpen
                )?_c('p',{staticClass:"item-modal-desc secondary-font mb-5"},[_vm._v(" "+_vm._s(_vm.item.description)+" ")]):_c('div',[_c('p',{staticClass:"item-modal-desc secondary-font mb-5"},[_vm._v(" "+_vm._s(_vm.item.description.substring(0, 100) + "...")+" ")])]),(_vm.isMobile && _vm.item.description.length >= 100)?_c('button',{staticClass:"item-modal-read-more mb-6",on:{"click":function($event){_vm.descriptionOpen = !_vm.descriptionOpen}}},[_vm._v(" "+_vm._s(_vm.descriptionOpen ? "Read less" : "Read more")+" ")]):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"has-text-left mb-6"},[_c('a',{staticClass:"single-nft-objkt-link",attrs:{"href":_vm.item.objktUrl,"target":"_blank"}},[_vm._v("View on Objkt")])]):_vm._e(),_c('div',{staticClass:"item-modal-bottom-sec"},[(_vm.isMobile)?_c('span',{staticClass:"item-modal-buy-now-label secondary-font"},[_vm._v(" Buy now ")]):_vm._e(),(
                    _vm.item.currentListings && _vm.item.currentListings.length > 0
                  )?_c('div',{staticClass:"item-modal-bottom-sec-cost",class:_vm.isMobile ? 'mb-5' : ''},[_vm._v(" "+_vm._s(_vm.item.currentListings[0].price_xtz / 1000000)+" XTZ ")]):_c('div',{staticClass:"item-modal-bottom-sec-cost",class:_vm.isMobile ? 'mb-5' : ''},[_vm._v(" NOT LISTED ")]),(!_vm.userStore.synced || _vm.userStore.synced == '')?_c('div',{staticClass:"item-modal-buttons-box mt-6"},[_c('my-button',{staticClass:"is-block mobile-full-width",attrs:{"disabled":_vm.userStore.syncing},on:{"clicked":_vm.userStore.sync}},[_vm._v(" SYNC ")])],1):_vm._e(),(_vm.userStore.synced != '')?_c('div',{staticClass:"item-modal-buttons-box mt-6"},[_c('my-button',{staticClass:"is-block mobile-full-width",attrs:{"disabled":true}},[_vm._v(" CONNECTED ")]),_c('div',{staticClass:"item-modal-purchase-buttons-box mt-4"},[_c('my-button',{staticClass:"mobile-full-width",attrs:{"disabled":_vm.isBuying || _vm.item.currentListings.length == 0},on:{"clicked":_vm.onBuyOpen}},[_vm._v(" BUY ")]),_c('my-button',{staticClass:"item-modal-offer-btn mobile-full-width",class:_vm.isMobile ? 'mt-4' : 'ml-4',attrs:{"disabled":_vm.isBuying || _vm.item.currentListings.length == 0},on:{"clicked":_vm.onOfferOpen}},[_vm._v(" MAKE AN OFFER ")])],1)],1):_vm._e()])])])])])]),_c('div',{staticClass:"row",class:!_vm.isMobile ? 'px-6 mt-6 pt-6' : 'mt-4'},[_c('history',{attrs:{"transactions":_vm.item.history}})],1)]),(_vm.focused)?_c('div',{staticClass:"item-modal-focus-ctn",on:{"click":_vm.onFocusOut}},[_c('div',{staticClass:"item-modal-focus-background"}),(_vm.item.mime.startsWith('audio'))?_c('div',{staticClass:"item-modal-focus-img",style:('background-image: url(' +
        _vm.item.display_uri.replace(
          'ipfs://',
          'https://ipfs.yomi.digital/ipfs/'
        ) +
        ');')},[(!this.isPlay)?_c('audio',{attrs:{"autoplay":"","src":_vm.item.artifact_uri.replace(
            'ipfs://',
            'https://ipfs.yomi.digital/ipfs/'
          )}}):_vm._e()]):(!_vm.item.mime.startsWith('video'))?_c('div',{staticClass:"item-modal-focus-img",style:('background-image: url(' +
        _vm.item.artifact_uri.replace(
          'ipfs://',
          'https://ipfs.yomi.digital/ipfs/'
        ) +
        ');')}):_c('div',{staticClass:"item-modal-focus-video"},[_c('video',{attrs:{"preload":"auto","loop":"","autoplay":"","muted":"","defaultMuted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.item.artifact_uri.replace(
              'ipfs://',
              'https://ipfs.yomi.digital/ipfs/'
            )}})])]),(!_vm.isMobile)?_c('Loader'):_vm._e()],1):_vm._e(),_c('my-modal',{attrs:{"isOpen":_vm.isBuyModalOpen},on:{"backgroundClicked":_vm.onBuyClose,"closeClicked":_vm.onBuyClose}},[(!_vm.isBuying)?_c('div',[_c('div',{staticClass:"item-modal-buy-title secondary-font"},[_vm._v("Buy now")]),(_vm.item.currentListings && _vm.item.currentListings.length > 0)?_c('div',{staticClass:"mt-3 mb-5 item-modal-buy-description"},[_vm._v(" Buy it for "+_vm._s(_vm.item.currentListings[0].price_xtz / 1000000)+" Tezos ")]):_c('div',{staticClass:"mt-3 mb-5 item-modal-buy-description"},[_vm._v(" NOT LISTED ")]),_c('my-button',{attrs:{"disabled":_vm.isBuying},on:{"clicked":_vm.buy}},[_vm._v(" BUY ")])],1):_c('div',{staticClass:"item-modal-waiting-box"},[_c('span',[_vm._v(" Waiting for blockchain... ")])])]),_c('my-modal',{attrs:{"isOpen":_vm.isOfferModalOpen},on:{"backgroundClicked":_vm.onOfferClose,"closeClicked":_vm.onOfferClose}},[(!_vm.isBuying)?_c('div',[_c('div',{staticClass:"item-modal-buy-title secondary-font"},[_vm._v("Offer")]),_c('div',{staticClass:"mt-3 mb-5 item-modal-buy-description"},[_vm._v(" Place an offer in this token ")]),_c('div',{staticClass:"item-modal-buy-inputs rows is-hidden-tablet"},[_c('div',{staticClass:"column is-3 item-modal-buy-inputs-single"},[_c('div',{staticClass:"secondary-font current-offer-text"},[_vm._v("Current offer")]),_c('div',{staticClass:"secondary-font"},[_vm._v(_vm._s(_vm.item.highestOffer))])]),_c('div',{staticClass:"row item-modal-buy-inputs-single mt-2"},[_c('my-input-field',{staticClass:"item-modal-buy-input-field",attrs:{"label":"Make your offer","placeholder":"20.00 (Value in Tezos)","type":"number"},on:{"input":_vm.onInputOffer}})],1),_c('div',{staticClass:"row item-modal-buy-inputs-single mt-4"},[_c('my-button',{staticClass:"item-modal-buy-input-button mobile mt-3",attrs:{"disabled":_vm.isBuying ||
              _vm.inputOffer == null ||
              _vm.inputOffer == undefined ||
              _vm.inputOffer == ''},on:{"clicked":_vm.offer}},[_vm._v(" OFFER ")])],1)]),_c('div',{staticClass:"item-modal-buy-inputs columns is-hidden-mobile is-justify-content-center"},[_c('div',{staticClass:"column is-3 item-modal-buy-inputs-single"},[_c('my-input-field',{staticClass:"item-modal-buy-input-field",attrs:{"label":"Current offer","placeholder":_vm.item.highestOffer,"disabled":true}})],1),_c('div',{staticClass:"column is-6 item-modal-buy-inputs-single"},[_c('my-input-field',{staticClass:"item-modal-buy-input-field",attrs:{"label":"Make your offer","placeholder":"20.00 (Value in Tezos)","type":"number"},on:{"input":_vm.onInputOffer}})],1),_c('div',{staticClass:"column is-2 item-modal-buy-inputs-single has-text-left"},[_c('my-button',{staticClass:"item-modal-buy-input-button",attrs:{"disabled":_vm.isBuying ||
              _vm.inputOffer == null ||
              _vm.inputOffer == undefined ||
              _vm.inputOffer == ''},on:{"clicked":_vm.offer}},[_vm._v(" OFFER ")])],1)])]):_c('div',{staticClass:"item-modal-waiting-box"},[_c('span',[_vm._v(" Waiting for blockchain... ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }