<template>
  <div @click="onCardClick" class="column is-4">
    <div :class="{'mb-4' : isMobile}" class="listing-card-ctn">
      <a
        :href="
          '/#/cross/' + listingItem.contractAddress + '/' + listingItem.token_id
        "
      >
        <LazyComponent class="listing-card-lazy">
          <div
            v-if="listingItem.mime.startsWith('audio') && !isMobile"
            class="listing-card-image-desk is-relative"
            :class="loadedMedia ? '' : 'listing-card-not-loaded'"
            @load="onLoadImg"
            :style="{
              backgroundImage:
                'url(' +
                listingItem.display_uri.replace(
                  'ipfs://',
                  'https://ipfs.yomi.digital/ipfs/'
                ) +
                ')',
            }"
          >
            <!-- <audio controls :src="listingItem.artifact_uri.replace('ipfs://', 'https://ipfs.yomi.digital/ipfs/')"></audio> -->
          </div>

          <img
            v-else-if="!listingItem.mime.startsWith('video') && isMobile"
            class="listing-card-image-mob"
            :class="loadedMedia ? '' : 'listing-card-not-loaded'"
            @load="onLoadImg"
            :src="
              listingItem.display_uri.replace(
                'ipfs://',
                'https://ipfs.yomi.digital/ipfs/'
              )
            "
          />

          <!-- <audio controls :src="listingItem.artifact_uri.replace('ipfs://', 'https://ipfs.yomi.digital/ipfs/')"></audio> -->

          <div
            v-else-if="!listingItem.mime.startsWith('video') && !isMobile"
            class="listing-card-image-desk"
            :class="loadedMedia ? '' : 'listing-card-not-loaded'"
            @load="onLoadImg"
            :style="{
              backgroundImage:
                'url(' +
                listingItem.artifact_uri.replace(
                  'ipfs://',
                  'https://ipfs.yomi.digital/ipfs/'
                ) +
                ')',
            }"
          ></div>

          <img
            v-else-if="!listingItem.mime.startsWith('video') && isMobile"
            class="listing-card-image-mob"
            :class="loadedMedia ? '' : 'listing-card-not-loaded'"
            @load="onLoadImg"
            :src="
              listingItem.artifact_uri.replace(
                'ipfs://',
                'https://ipfs.yomi.digital/ipfs/'
              )
            "
          />
          <video
            v-else
            :class="{
              'listing-card-image-mob': isMobile,
              'listing-card-image-desk': !isMobile,
              'listing-card-not-loaded': !loadedMedia,
            }"
            preload="metadata"
            @loadeddata="onLoadVideo"
            autoplay
            playsinline
            loop
            muted
          >
            <source
              :src="
                listingItem.artifact_uri.replace(
                  'ipfs://',
                  'https://ipfs.yomi.digital/ipfs/'
                )
              "
            />
          </video>
          <Loader slot="placeholder" />
          <!-- <Loader v-if="!loadedMedia" /> -->
        </LazyComponent>
        <div
          class="listing-card-chips-ctn"
          :class="!isMobile ? 'mt-5 pt-2 mb-2' : 'mt-4'"
        >
          <div
            v-if="listingItem && listingItem.featured"
            class="listing-card-chip px-3 py-1"
          >
            Featured
          </div>
          <div
            class="listing-card-chip py-1"
            :class="!isMobile ? 'px-3 ' : 'px-3 mb-1'"
          >
            {{
              listingItem.listing == "one-to-one" ? "Objkt 1.1" : "Objkt Ed."
            }}
          </div>
        </div>
        <a class="listing-card-artist artist-name mt-1 mb-1" :href="'/#/artist/' + this.addressCreator">
          {{ listingItem.artistName }}
      

        </a>
        <!-- <div class="listing-card-artist mb-3">
        </div> -->
        <div
          class="listing-card-name secondary-font"
          :class="!isMobile ? 'mb-3 ' : 'mb-1 mt-2'"
        >
          {{ listingItem.name }}
        </div>
        <div
          v-if="listingItem.currentListings.length > 0"
          class="listing-card-price secondary-font"
        >
          {{ listingItem.currentListings[0].price_xtz / 1000000 }} XTZ
        </div>
        <div v-else class="listing-card-price secondary-font">NOT LISTED</div>
        <!-- <a :href="'/#/cross/' + listingItem.id">
      <button style="cursor: pointer">BUY</button>
    </a> -->
      </a>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import LazyComponent from "v-lazy-component";
import checkViewport from "../mixins/checkViewport.js";

export default {
  name: "ListingCard",
  props: ["listingItem"],
  mixins: [checkViewport],
  methods: {
    onCardClick() {
      this.$emit("cardClicked");
    },
    onLoadImg() {
      console.log("loaded img");
      this.loadedMedia = true;
    },
    onLoadVideo() {
      console.log("loaded video");
      this.loadedMedia = true;
    },
    getAddress() {
      const app = this
      app.listingCreator = app.listingItem.creators
      /* console.log('==>',app.listingCreator); */
      if(app.listingCreator.length > 1){

        const filteredArray = app.listingCreator.filter(
          (obj) => obj.holder.alias !== "Fakewhale | CROSS"
        );
        app.addressCreator = filteredArray.find(
        (obj) => obj.creator_address
      ).creator_address;
      }else{
        app.addressCreator = app.listingCreator.find(
        (obj) => obj.creator_address
      ).creator_address;
      }
      
      /* console.log(app.addressCreator); */
    },
  },
  components: {
    LazyComponent,
    Loader,
  },
  data() {
    return {
      loadedMedia: false,
      listingCreator:[],
      addressCreator:""
    };
  },
  mounted() {
    const app = this;
    app.getAddress();
  },
};
</script>

<style>
.listing-card-ctn {
  cursor: pointer;
}

.listing-card-lazy {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 300px; */
}

/* .listing-card-not-loaded {
  opacity: 0;
} */

.listing-card-artist {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listing-card-chips-ctn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}

.listing-card-chip {
  color: var(--my-grey);
  font-size: 11px;
  border: 1px solid var(--my-grey);
  border-radius: 100px;
  text-align: center;
}

.listing-card-image-desk {
  height: 300px;
  width: 100%;

  /* background-image: url("/loading.gif"); */

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
}
.listing-card-image-mob {
  width: 100%;
  max-height: 370px;
  object-fit: cover;

  display: inline-block;
}

.listing-card-name {
  color: var(--my-grey);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listing-card-price {
  font-size: 14px;
}

.listing-card-link {
  color: var(--my-grey);
  font-size: 11px;
  border-color: var(--my-grey);
}

@media screen and (max-width: 768px) {
  /* .listing-card-image-desk {
    height: 170px;
  } */

  /* .listing-card-lazy {
    min-height: 300px;
  } */

  .listing-card-chips-ctn {
    gap: 1px;
    justify-content: start;
  }

  .listing-card-chip {
    color: var(--my-grey);
    font-size: 10px;
    border: 1px solid var(--my-grey);
    border-radius: 100px;
    text-align: center;
  }

  .listing-card-artist {
    font-size: 13px;
    text-align: left;
    display: block;
    width: fit-content;
  }

  .listing-card-name {
    font-size: 12px;
    text-align: left;
  }
  .listing-card-price {
    font-size: 12px;
    text-align: left;
  }
  .listing-card-link-ctn {
    text-align: left;
  }
}
</style>
