<template>
  <icon-button @iconClicked="onCloseClick">
    <img src="/close.svg" />
  </icon-button>
</template>

<script>
import IconButton from "./IconButton.vue";

export default {
  components: { IconButton },
  name: "CloseButton",
  data() {
    return {};
  },
  methods: {
    onCloseClick() {
      this.$emit("closeClicked");
    },
  },
};
</script>

<style>
.close-button {
  background: #121212;
  border: 1px solid var(--my-grey-dark);
  border-radius: 4px;
  color: var(--my-grey-dark);
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>