<template>
  <div id="app">
    <navbar />
    <router-view />
    <my-footer />
  </div>
</template>

<script>
import { mapStores } from "pinia";
import MyFooter from "./components/MyFooter.vue";
import Navbar from "./components/Navbar.vue";
// import { useUserStore } from "./stores/userStore";
export default {
  name: "App",
  components: { Navbar, MyFooter },
  watch: {
    $route(to, from) {
      if (from.name == "SingleNft") {
        document
          .getElementsByClassName("nav")[0]
          .classList.toggle("blur-content", false);
        document
          .getElementsByTagName("html")[0]
          .classList.toggle("no-overflow", false);
        if (!this.isMobile) {
          document
            .getElementsByClassName("footer")[0]
            .classList.toggle("blur-content", false);
        } else {
          document
            .getElementsByClassName("footer-mobile")[0]
            .classList.toggle("blur-content", false);
        }
      }
    },
  },
  // computed: {
  //   ...mapStores(useUserStore),
  // },
  // async mounted() {
  //   await this.userStore.checkSync();
  // },
};
</script>