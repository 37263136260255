<template>
  <div>
    <div class="column" style="padding: 45vh 0; text-align: center" v-if="art.tokenId === undefined">
      Obtaining art informations,<br />
      please wait..
    </div>
    <div v-if="art.tokenId !== undefined">
      <div class="container">
        <div class="columns">
          <div class="column artist">
            <!--VIDEO-->
            <div>
              <LazyComponent>
                <div style="position: relative;">
                  <video @click="initFullscreenVideo(art.metadata.fullscreenUri)" class="video-home" preload="auto" loop autoplay :muted="videoIsMuted" defaultMuted playsinline
                    v-if="art.isVideo" style="width: 100%; cursor: zoom-in">
                    <source :src="art.metadata.artifactUri" />
                  </video>
                  <div class="video-audio" v-bind:class="{ 'force-bottom': showFullVideo }"
                    v-if="art.hasAudio !== undefined && art.hasAudio === true" @click="toggleMuted"><i
                      v-if="!videoIsMuted" class="mdi mdi-volume-high"></i><i v-if="videoIsMuted"
                      class="mdi mdi-volume-off"></i></div>
                </div>
                <Loader slot="placeholder" />
              </LazyComponent>
            </div>
            <!--VIDEO-->
            <!--IMAGE-->
            <div class="nft-image" v-if="!art.isVideo && !art.isHtml" @click="initFullscreen(art.metadata.fullscreenUri)"
              style="cursor: zoom-in">
              <LazyComponent>
                <img :src="art.metadata.artifactUri.replace(
                  'ipfs://',
                  'https://ipfs.yomi.digital/ipfs/'
                )
                  " :alt="art.metadata.name" transition-duration="50" width="100%" />
                <Loader slot="placeholder" />
              </LazyComponent>
            </div>
            <!--IMAGE-->
            <!--HTML-->
            <div v-if="art.isHtml">
              <iframe allowfullscreen :src="art.metadata.artifactUri
                .replace(
                  'https://cdn.fakewhale.xyz/',
                  'https://ipfs.yomi.digital/ipfs/'
                )
                .replace('.jpg', '')
                " style="width: 100%; height: 90vh"></iframe>
            </div>
            <!--HTML-->
            <div class="links-wrapper">
              <h1 class="title is-3 secondary-font">{{ art.metadata.name }}</h1>
              <a :href="'/#/artist/' + art.from.address">{{ art.from.alias }}</a><br />
              BLOCK #<a :href="explorerEndpoint.replace('{block}', art.level)" target="_blank">{{ art.level }}</a>
              <h1 class="title is-5">NOT FOR SALE</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="description" style="text-align: center" v-html="art.metadata.description"></div>
          </div>
        </div>
        <div style="padding-bottom: 100px">
          <a class="item-modal-back-btn secondary-text secondary-font read m-auto" href="/" style="color: #fff">Back to vault</a>
        </div>
      </div>
    </div>
    <!-- FULLSCREEN-->
    <div class="fullscreen" :style="'background-image:url(' + fullscreenImg + '); background-size: contain;'
      " v-if="showFull" @click="hideFullScreenImage()"></div>
    <div class="fullscreen" style="background-color: #000; color: #fff" v-if="showFullVideo"
      @click="hideFullScreenVideo()">
      <video style="height: 100vh; width: 100%" autoplay :muted="videoIsMuted" loop playsinline>
        <source :src="fullscreenVideo" type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    </div>
    <div style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 99;
      " id="black"></div>
    <!-- FULLSCREEN-->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import LazyComponent from "v-lazy-component";

import Loader from "../components/Loader.vue";

export default {
  name: "Art",
  data() {
    return {
      nfts: [],
      art: {},
      axios: axios,
      black: false,
      explorerEndpoint: process.env.VUE_APP_EXPLORER_ENDPOINT,
      showFull: false,
      fullscreenImg: "",
      showFullVideo: false,
      fullscreenVideo: "",
      scroll: 0,
      loaded: false,
      videoIsMuted: true,
    };
  },
  components: {
    LazyComponent,
    Loader,
  },
  async mounted() {
    const app = this;
    const art = await app.axios.get(
      process.env.VUE_APP_LAMBDA_URL + "/art/" + app.$route.params.tokenId
    );
    if (art.data.isVideo === undefined) {
      art.data.isVideo = false;
    }
    art.data.art.metadata.artifactUri =
      art.data.art.metadata.artifactUri.replace(
        "ipfs://",
        "https://ipfs.yomi.digital/ipfs/"
      );
    art.data.art.metadata.fullscreenUri =
      art.data.art.metadata.artifactUri.replace(
        "ipfs://",
        "https://ipfs.yomi.digital/ipfs/"
      );
    app.art = art.data.art;
    app.loaded = true;
  },
  methods: {
    toggleMuted() {
      const app = this;
      app.videoIsMuted = !app.videoIsMuted;
    },
    initFullscreen(img) {
      const app = this;
      app.fullscreenImg = img;
      app.showFull = true;
      document.querySelector("html").style.overflow = "hidden";
      app.scroll = $("html").scrollTop();
    },
    initFullscreenVideo(src) {
      const app = this;
      app.fullscreenVideo = src;
      app.showFullVideo = true;
      document.querySelector("html").style.overflow = "hidden";
      app.scroll = $("html").scrollTop();
    },
    hideFullScreenImage() {
      const app = this;
      app.showFull = false;
      $("#black").css("display", "block");
      setTimeout(function () {
        $("html").scrollTop(app.scroll);
        document.querySelector("html").style.overflow = "inherit";
        $("#black").css("display", "none");
      }, 1);
    },
    hideFullScreenVideo() {
      const app = this;
      app.showFullVideo = false;
      $("#black").css("display", "block");
      setTimeout(function () {
        $("html").scrollTop(app.scroll);
        document.querySelector("html").style.overflow = "inherit";
        $("#black").css("display", "none");
      }, 1);
    },
  },
};
</script>
