<template>
  <div class="filter-group">
    <h6 class="mb-3 mt-5 secondary-font">{{ title }}</h6>
    <div
      :class="
        expanded ? 'filter-group-expanded scrollbar-semi-transparent' : ''
      "
    >
      <b-field
        v-for="option in options.slice(
          0,
          expanded ? options.length : initialItemsCount
        )"
        :key="option.key"
      >
        <b-checkbox
          :disabled="disabled"
          class="filter-checkbox"
          @input="(evt) => OnFilterClick(option.key, evt)"
          :value="option.checked"
        >
          {{ option.label }}
        </b-checkbox>
      </b-field>
      <button
        class="filter-group-show-more"
        v-if="!expanded && options.length > initialItemsCount"
        @click="OnShowMoreClick"
        :disabled="disabled"
      >
        Show more
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterGroup",
  props: ["title", "options", "disabled"],
  data() {
    return {
      expanded: false,
      initialItemsCount: 4,
    };
  },
  methods: {
    OnFilterClick(key, value) {
      this.$emit("filterClick", key, value);
    },
    OnShowMoreClick() {
      this.expanded = true;
    },
  },
};
</script>

<style>
.filter-checkbox {
  width: 100%;
}

.filter-checkbox.b-checkbox.checkbox input[type="checkbox"] + .check {
  border-width: 0.5px;
  border-color: #424242;
}
.filter-checkbox.b-checkbox.checkbox:hover
  input[type="checkbox"]:not(:disabled)
  + .check,
.filter-checkbox.b-checkbox.checkbox:hover
  input[type="checkbox"]:not(:disabled)
  + .check {
  border-color: #ffffff;
}

.filter-group-expanded {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 12px;
}

.filter-group-show-more {
  color: var(--my-grey);
  font-size: 12px;
  border-color: var(--my-grey);
  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}
.filter-group-show-more:hover:not(:disabled) {
  color: #ccc;
}
.filter-group-show-more:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

.filter-checkbox.b-checkbox.checkbox input[type="checkbox"]:checked + .check {
  background: #000000
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' %3E%3Ccircle r='5' cx='10' cy='10' fill='white' /%3E%3C/svg%3E")
    no-repeat center center;
}

.filter-checkbox .control-label {
  font-size: 13px;
  text-overflow: ellipsis;
  width: max-content;
  overflow: hidden;
}
</style>
