<template>
  <button class="icon-button" @click="onIconClick">
    <slot />
  </button>
</template>

<script>
export default {
  name: "IconButton",
  data() {
    return {};
  },
  methods: {
    onIconClick() {
      this.$emit("iconClicked");
    },
  },
};
</script>

<style>
.icon-button {
  background: #121212;
  border: 1px solid var(--my-grey-dark);
  border-radius: 4px;
  color: var(--my-grey-dark);
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>