<template>
  <div>
    <div
      class="column"
      style="padding: 45vh 0; text-align: center"
      v-if="artist.artistId === undefined"
    >
      Obtaining artist informations,<br />
      please wait..
    </div>
    <div v-if="artist.artistId !== undefined">
      <div class="container">
        <div class="columns">
          <div class="column artist">
            <div class="avatar-wrapper">
              <div
                v-if="artist.avatar"
                :style="
                  'background-image:url(' +
                  artist.avatar +
                  '); background-size:cover'
                "
                class="avatar"
              >
                <img src="/check.svg" width="40" />
              </div>
            </div>
            <div class="links-wrapper">
              <h1 v-if="artist.name" class="title is-3 secondary-font">{{ artist.name }}</h1>
              {{ artist.artistId }}
            </div>
          </div>
        </div>
      </div>
      <div class="items">
        <div class="item" v-for="img in artist.gallery" v-bind:key="img.img">
          <LazyComponent>
            <img
              v-if="
                img.isVideo === undefined ||
                (img.isVideo !== undefined && !img.isVideo)
              "
              :src="img.img"
            
              class="style-media"
            />
            <video
            class="style-media"
              v-if="img.isVideo !== undefined && img.isVideo"
              preload="auto"
              loop
              autoplay
              muted
              defaultMuted
              playsinline
            >
              <source :src="img.img" type="video/mp4" />
              Your browser does not support HTML video.
            </video>
            <Loader slot="placeholder" />
          </LazyComponent>

          <a v-if="img.link !== '#' && img.link !== ''" :href="img.link" target="_blank">
            <span v-if="img.isMinted === undefined">minted</span>
            <span v-if="img.isMinted !== undefined && img.isMinted === true"
              >minted</span
            >
            <span v-if="img.isMinted !== undefined && img.isMinted === false"
              >see</span
            >
            on
            <span
              style="font-weight: bold"
              v-if="
                img.isMinted === undefined && img.link.indexOf('.com') !== -1
              "
              >{{
                img.link
                  .split(".com")[0]
                  .replace("https://", "")
                  .replace("www.", "")
              }}</span
            >
            <span
              style="font-weight: bold"
              v-if="
                img.isMinted !== undefined &&
                img.isMinted === true &&
                img.link.indexOf('.com') !== -1
              "
              >{{
                img.link
                  .split(".com")[0]
                  .replace("https://", "")
                  .replace("www.", "")
              }}</span
            >
            <span
              style="font-weight: bold"
              v-if="
                img.isMinted !== undefined &&
                img.isMinted === false &&
                img.link.indexOf('.com') !== -1
              "
              >{{
                img.link
                  .split(".com")[0]
                  .replace("https://", "")
                  .replace("www.", "")
              }}.com</span
            >
            <span
              style="font-weight: bold"
              v-if="img.link.indexOf('.io') !== -1"
              >{{ img.link.split(".io")[0].replace("https://", "") }}</span
            >
            <span
              style="font-weight: bold"
              v-if="img.link.indexOf('.app') !== -1"
              >{{ img.link.split(".app")[0].replace("https://", "") }}</span
            >
            <span
              style="font-weight: bold"
              v-if="img.link.indexOf('.art') !== -1"
              >{{ img.link.split(".art")[0].replace("https://", "") }}</span
            >
            <span
              style="font-weight: bold"
              v-if="img.link.indexOf('.xyz') !== -1"
              >{{
                img.link
                  .split(".xyz")[0]
                  .replace("https://", "")
                  .replace("www.", "")
              }}</span
            >
          </a>
        </div>
      </div>
      <div class="container">
        <div class="columns">
          <div class="column">
            <div v-if="artist.description" class="description secondary-font" v-html="artist.description"></div>
            <div
              class="columns"
              style="padding: 60px 0 100px 0; border-top: 1px solid #ddd"
            >
              <div v-if="artist.web2 !== undefined && artist.web2.length > 0" class="column" style="text-align: left">
                web2:<br />
                <div v-for="link in artist.web2" v-bind:key="link.link">
                  <a :href="link.link" target="_blank">{{ link.type }}</a>
                </div>
              </div>
              <div
                class="column"
                v-if="artist.web3 !== undefined && artist.web3.length > 0"
                style="text-align: left"
              >
                web3:<br />
                <div v-for="link in artist.web3" v-bind:key="link.link">
                  <a :href="link.link" target="_blank">{{ link.type }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding-bottom: 100px">
          <a class="item-modal-back-btn secondary-text secondary-font read m-auto" href="/" style="color: #fff">Back to vault</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LazyComponent from "v-lazy-component";
import Loader from "@/components/Loader.vue";
export default {
  name: "Artist",
  data() {
    return {
      nfts: [],
      artist: {},
      axios: axios,
      explorerEndpoint: process.env.VUE_APP_EXPLORER_ENDPOINT,
      loaded: false,
    };
  },
  components: {
    LazyComponent,
    Loader,
  },
  async mounted() {
    const app = this;
    const artist = await app.axios.get(
      process.env.VUE_APP_LAMBDA_URL + "/artists/" + app.$route.params.artistId
    );
    app.artist = artist.data.artist;
    /* console.log('artist',app.artist); */
    app.loaded = true;
    setTimeout(function () {
      const slider = document.querySelector(".items");
      let isDown = false;
      let startX;
      let scrollLeft;
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    }, 200);
  },
};
</script>
