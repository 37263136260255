import { defineStore } from "pinia";

// import { NetworkType } from "@airgap/beacon-sdk";
// import { BeaconWallet } from "@taquito/beacon-wallet";
// import { TezosToolkit } from "@taquito/taquito";

// const Tezos = new TezosToolkit(process.env.VUE_APP_TOOKLIT_ENDPOINT);
// const beacon = new BeaconWallet({ name: "Fakewhale" });
// Tezos.setWalletProvider(beacon);

export const useUserStore = defineStore("user", {
  state: () => ({
    pubKey: "",
    address: "",
    synced: "",
    // Tezos,
    // beacon,
    syncing: false,
  }),
  actions: {
    // async sync() {
    //   const app = this;
    //   try {
    //     console.log("Asking permissions...");
    //     app.syncing = true;
    //     const permissions = await beacon.client.requestPermissions({
    //       network: { type: NetworkType.MAINNET },
    //     });
    //     console.log("Got permissions:", permissions);
    //     app.synced = permissions.address;
    //     app.pubKey = permissions.publicKey;
    //     app.syncing = false;
    //   } catch (error) {
    //     console.log("Got error:", error);
    //     app.syncing = false;
    //   }
    // },
    async checkSync() {
      const app = this;
      try {
        // console.log("Checking permissions...");
        // app.syncing = true;
        // const permissions = await beacon.client.getActiveAccount();
        // console.log("Checked permissions:", permissions);
        // if (permissions) {
        //   app.synced = permissions.address;
        //   app.pubKey = permissions.publicKey;
        // }
        app.syncing = false;
      } catch (error) {
        console.log("Got error:", error);
        app.syncing = false;
      }
    },
    // async unsync() {
    //   const app = this;
    //   app.account = "";
    //   app.synced = "";
    //   app.pubKey = "";
    //   await app.beacon.clearActiveAccount();
    //   localStorage.removeItem("tz_account");
    //   localStorage.removeItem("tz_artist");
    //   localStorage.removeItem("tz_pubkey");
    // },
  },
});
