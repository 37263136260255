<template>
  <div class="item-modal-root">
    <div class="rows">
      <div class="row mb-3">
        <button @click="onBackClick" class="item-modal-back-btn secondary-text secondary-font read">
          Back
        </button>
      </div>
      <div class="row">
        <div class="columns is-centered">
          <div class="column pl-2 pr-5 py-6">
            <div
              v-if="item.mime.startsWith('audio')"
              class="item-modal-img-box is-relative"
            >
              <!-- intervieni in caso qua -->
              <img
                @click="onFocusIn"
                class="item-modal-img"
                style="width: 75%"
                :src="
                  item.display_uri.replace(
                    'ipfs://',
                    'https://ipfs.yomi.digital/ipfs/'
                  )
                "
              />
              <div class="item-modal-img-filter secondary-font"></div>
              <img
                v-if="!isPlay"
                class="audio-control"
                @click="playAudio"
                src="../assets/sound_max_fill.svg"
                alt=""
              />
              <img
                v-if="isPlay"
                class="audio-control"
                @click="playAudio"
                src="../assets/sound_mute_fill.svg"
                alt=""
              />
              <audio
                ref="audioPlayer"
                :src="
                  item.artifact_uri.replace(
                    'ipfs://',
                    'https://ipfs.yomi.digital/ipfs/'
                  )
                "
              ></audio>
            </div>
            <div
              v-else-if="!item.mime.startsWith('video')"
              class="item-modal-img-box"
            >
              <img
                @click="onFocusIn"
                class="item-modal-img"
                :src="
                  item.artifact_uri.replace(
                    'ipfs://',
                    'https://ipfs.yomi.digital/ipfs/'
                  )
                "
              />

              <div class="item-modal-img-filter secondary-font"></div>
            </div>
            <div v-else class="item-modal-video-box">
              <video
                @click="onFocusIn"
                class="item-modal-video"
                preload="auto"
                loop
                autoplay
                muted
                defaultMuted
                playsinline
              >
                <source
                  :src="
                    item.artifact_uri.replace(
                      'ipfs://',
                      'https://ipfs.yomi.digital/ipfs/'
                    )
                  "
                />
              </video>
              <div class="item-modal-img-filter secondary-font"></div>
            </div>
          </div>
          <div class="column pt-6">
            <div class="columns">
              <div
                class="column is-auto pt-3 has-text-left"
                
              >
                <a
                  class="item-modal-artist artist-name mb-3 is-inline-block"
                  :href="'/#/artist/' + this.addressCreator"
                  
                >
                  {{ item.artistName }}
              
                </a>
             
                <p
                  class="item-modal-sub secondary-font"
                  :class="!isMobile ? 'mb-6' : 'mb-2'"
                >
                  {{ item.name }}
                </p>

                <div
                  v-if="isMobile"
                  class="item-modal-objkt-mobile has-text-left-mobile mb-5"
                >
                  <a :href="item.objktUrl" target="_blank">View on Objkt</a>
                </div>

                <p
                  v-if="
                    !isMobile ||
                    item.description.length < 100 ||
                    descriptionOpen
                  "
                  class="item-modal-desc secondary-font mb-5"
                >
                  {{ item.description }}
                </p>
                <div v-else>
                  <p class="item-modal-desc secondary-font mb-5">
                    {{ item.description.substring(0, 100) + "..." }}
                  </p>
                </div>
                <button
                  v-if="isMobile && item.description.length >= 100"
                  class="item-modal-read-more mb-6"
                  @click="descriptionOpen = !descriptionOpen"
                >
                  {{ descriptionOpen ? "Read less" : "Read more" }}
                </button>
                <div v-if="!isMobile" class="has-text-left mb-6">
                  <a
                    :href="item.objktUrl"
                    target="_blank"
                    class="single-nft-objkt-link"
                    >View on Objkt</a
                  >
                </div>
                <div class="item-modal-bottom-sec">
                  <span
                    v-if="isMobile"
                    class="item-modal-buy-now-label secondary-font"
                  >
                    Buy now
                  </span>
                  <div
                    class="item-modal-bottom-sec-cost"
                    :class="isMobile ? 'mb-5' : ''"
                    v-if="
                      item.currentListings && item.currentListings.length > 0
                    "
                  >
                    {{ item.currentListings[0].price_xtz / 1000000 }} XTZ
                  </div>
                  <div
                    class="item-modal-bottom-sec-cost"
                    :class="isMobile ? 'mb-5' : ''"
                    v-else
                  >
                    NOT LISTED
                  </div>
                  <div
                    v-if="!userStore.synced || userStore.synced == ''"
                    class="item-modal-buttons-box mt-6"
                  >
                    <my-button
                      :disabled="userStore.syncing"
                      @clicked="userStore.sync"
                      class="is-block mobile-full-width"
                    >
                      SYNC
                    </my-button>
                  </div>
                  <div
                    v-if="userStore.synced != ''"
                    class="item-modal-buttons-box mt-6"
                  >
                    <my-button
                      :disabled="true"
                      class="is-block mobile-full-width"
                    >
                      CONNECTED
                    </my-button>
                    <div class="item-modal-purchase-buttons-box mt-4">
                      <my-button
                        :disabled="isBuying || item.currentListings.length == 0"
                        @clicked="onBuyOpen"
                        class="mobile-full-width"
                      >
                        BUY
                      </my-button>
                      <my-button
                        class="item-modal-offer-btn mobile-full-width"
                        :class="isMobile ? 'mt-4' : 'ml-4'"
                        :disabled="isBuying || item.currentListings.length == 0"
                        @clicked="onOfferOpen"
                      >
                        MAKE AN OFFER
                      </my-button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="column is-2 is-hidden-mobile">
                <button
                  @click="onBackClick"
                  class="item-modal-back-btn secondary-text"
                >
                  Back
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row" :class="!isMobile ? 'px-6 mt-6 pt-6' : 'mt-4'">
        <history :transactions="item.history" />
        <!-- <history-mobile v-if="isMobile" :transactions="item.history" /> -->
      </div>
    </div>
    <div v-if="focused" class="item-modal-focus-ctn" @click="onFocusOut">
      <div class="item-modal-focus-background" />
      <div
        v-if="item.mime.startsWith('audio')"
        class="item-modal-focus-img"
        :style="
          'background-image: url(' +
          item.display_uri.replace(
            'ipfs://',
            'https://ipfs.yomi.digital/ipfs/'
          ) +
          ');'
        "
      >
        <audio
          v-if="!this.isPlay"
          autoplay
          :src="
            item.artifact_uri.replace(
              'ipfs://',
              'https://ipfs.yomi.digital/ipfs/'
            )
          "
        ></audio>
      </div>
      <div
        v-else-if="!item.mime.startsWith('video')"
        class="item-modal-focus-img"
        :style="
          'background-image: url(' +
          item.artifact_uri.replace(
            'ipfs://',
            'https://ipfs.yomi.digital/ipfs/'
          ) +
          ');'
        "
      ></div>
      <div v-else class="item-modal-focus-video">
        <video preload="auto" loop autoplay muted defaultMuted playsinline>
          <source
            :src="
              item.artifact_uri.replace(
                'ipfs://',
                'https://ipfs.yomi.digital/ipfs/'
              )
            "
          />
        </video>
      </div>
      <Loader v-if="!isMobile" />
    </div>
    <my-modal
      :isOpen="isBuyModalOpen"
      @backgroundClicked="onBuyClose"
      @closeClicked="onBuyClose"
    >
      <div v-if="!isBuying">
        <div class="item-modal-buy-title secondary-font">Buy now</div>
        <div
          v-if="item.currentListings && item.currentListings.length > 0"
          class="mt-3 mb-5 item-modal-buy-description"
        >
          Buy it for {{ item.currentListings[0].price_xtz / 1000000 }} Tezos
        </div>
        <div v-else class="mt-3 mb-5 item-modal-buy-description">
          NOT LISTED
        </div>
        <my-button @clicked="buy" :disabled="isBuying"> BUY </my-button>
      </div>
      <div class="item-modal-waiting-box" v-else>
        <span> Waiting for blockchain... </span>
      </div>
    </my-modal>
    <my-modal
      :isOpen="isOfferModalOpen"
      @backgroundClicked="onOfferClose"
      @closeClicked="onOfferClose"
    >
      <div v-if="!isBuying">
        <div class="item-modal-buy-title secondary-font">Offer</div>
        <div class="mt-3 mb-5 item-modal-buy-description">
          Place an offer in this token
        </div>
        <div class="item-modal-buy-inputs rows is-hidden-tablet">
          <div class="column is-3 item-modal-buy-inputs-single">
            <div class="secondary-font current-offer-text">Current offer</div>
            <div class="secondary-font">{{ item.highestOffer }}</div>
          </div>
          <div class="row item-modal-buy-inputs-single mt-2">
            <my-input-field
              class="item-modal-buy-input-field"
              label="Make your offer"
              placeholder="20.00 (Value in Tezos)"
              type="number"
              @input="onInputOffer"
            ></my-input-field>
          </div>
          <div class="row item-modal-buy-inputs-single mt-4">
            <my-button
              class="item-modal-buy-input-button mobile mt-3"
              @clicked="offer"
              :disabled="
                isBuying ||
                inputOffer == null ||
                inputOffer == undefined ||
                inputOffer == ''
              "
            >
              OFFER
            </my-button>
          </div>
        </div>
        <div
          class="item-modal-buy-inputs columns is-hidden-mobile is-justify-content-center"
        >
          <div class="column is-3 item-modal-buy-inputs-single">
            <my-input-field
              class="item-modal-buy-input-field"
              label="Current offer"
              :placeholder="item.highestOffer"
              :disabled="true"
            ></my-input-field>
          </div>
          <div class="column is-6 item-modal-buy-inputs-single">
            <my-input-field
              class="item-modal-buy-input-field"
              label="Make your offer"
              placeholder="20.00 (Value in Tezos)"
              type="number"
              @input="onInputOffer"
            ></my-input-field>
          </div>
          <div class="column is-2 item-modal-buy-inputs-single has-text-left">
            <my-button
              class="item-modal-buy-input-button"
              @clicked="offer"
              :disabled="
                isBuying ||
                inputOffer == null ||
                inputOffer == undefined ||
                inputOffer == ''
              "
            >
              OFFER
            </my-button>
          </div>
        </div>
      </div>
      <div class="item-modal-waiting-box" v-else>
        <span> Waiting for blockchain... </span>
      </div>
    </my-modal>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useUserStore } from "../stores/userStore";

import checkViewport from "../mixins/checkViewport.js";
import History from "./History.vue";
// import HistoryMobile from "./HistoryMobile.vue";
import Loader from "@/components/Loader.vue";
import MyButton from "./MyButton.vue";
import MyInputField from "./MyInputField.vue";
import MyModal from "./MyModal.vue";

export default {
  components: {
    MyButton,
    MyInputField,
    MyModal,
    History,
    Loader,
    // HistoryMobile,
  },
  name: "ItemModal",
  props: ["item"],
  mixins: [checkViewport],
  data() {
    return {
      focused: false,
      isBuying: false,
      error: false,
      lambdaURL: process.env.VUE_APP_LAMBDA_URL,
      contractAddress11: process.env.VUE_APP_11_CONTRACT_ADDRESS,
      contractAddressCross: process.env.VUE_APP_CROSS_CONTRACT_ADDRESS,
      pubKey: "",
      synced: "",
      mintingState: "",
      addressCreator: "",
      isBuyModalOpen: false,
      isOfferModalOpen: false,
      inputOffer: null,
      descriptionOpen: false,
      isPlay: false,
    };
  },
  computed: {
    ...mapStores(useUserStore),
  },
  methods: {
    playAudio() {
      const audio = this.$refs.audioPlayer;
      this.isPlay = !this.isPlay;
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    },
    onBackClick() {
      this.$emit("backClicked");
    },
    onFocusIn() {
      // this.$emit("itemFocusIn");
      this.focused = true;
      document
        .getElementsByClassName("nav")[0]
        .classList.toggle("neg-z-index", true);
      document
        .getElementsByTagName("html")[0]
        .classList.toggle("no-overflow", true);
      if (!this.isMobile) {
        document
          .getElementsByClassName("footer")[0]
          .classList.toggle("neg-z-index", true);
      } else {
        document
          .getElementsByClassName("footer-mobile")[0]
          .classList.toggle("neg-z-index", true);
      }
    },
    onFocusOut() {
      // this.$emit("itemFocusOut");
      this.focused = false;
      document
        .getElementsByClassName("nav")[0]
        .classList.toggle("neg-z-index", false);
      document
        .getElementsByTagName("html")[0]
        .classList.toggle("no-overflow", false);
      if (!this.isMobile) {
        document
          .getElementsByClassName("footer")[0]
          .classList.toggle("neg-z-index", false);
      } else {
        document
          .getElementsByClassName("footer-mobile")[0]
          .classList.toggle("neg-z-index", false);
      }
    },
    onInputOffer(val) {
      this.inputOffer = val;
    },
    onBuyOpen() {
      this.isBuyModalOpen = true;
      document
        .getElementsByTagName("html")[0]
        .classList.toggle("no-overflow", true);
    },
    onOfferOpen() {
      this.isOfferModalOpen = true;
      document
        .getElementsByTagName("html")[0]
        .classList.toggle("no-overflow", true);
    },
    onBuyClose() {
      this.isBuyModalOpen = false;
      document
        .getElementsByTagName("html")[0]
        .classList.toggle("no-overflow", false);
    },
    onOfferClose() {
      this.isOfferModalOpen = false;
      document
        .getElementsByTagName("html")[0]
        .classList.toggle("no-overflow", false);
    },
    async buy() {
      const app = this;
      if (app.item?.currentListings?.length > 0) {
        if (!app.isBuying) {
          app.isBuying = true;
          const activeAccount =
            await app.userStore.beacon.client.getActiveAccount();
          if (activeAccount) {
            try {
              console.log(
                "Creating contract instance at " +
                  process.env.VUE_APP_MARKETPLACE_ADDRESS
              );
              const contract = await app.userStore.Tezos.wallet.at(
                process.env.VUE_APP_MARKETPLACE_ADDRESS
              );
              console.log("Starting minting request..");
              console.log("Account is:", activeAccount);
              console.log("ITEM", app.item);
              const minting = await contract.methods
                .fulfill_ask([app.item.currentListings[0].bigmap_key])
                .send({
                  amount: app.item.currentListings[0].price_xtz / 1000000,
                });
              await minting.confirmation();
              app.$buefy.snackbar.open(
                `Nft minted correctly, now transfer to collection.`
              );
              app.mintingState = "Transferring, please wait..";
              app.$buefy.snackbar.open(`Buying complete!`);
              app.isBuying = false;
              app.mintingState = "Buying nft, please wait..";
            } catch (error) {
              console.log(error);
              app.isBuying = false;
            }
          } else {
            app.$buefy.snackbar.open(`Can't get active account, unsyncing.`);
            app.unsync();
          }
        }
      }
    },
    async offer() {
      const app = this;
      if (app.item?.currentListings?.length > 0) {
        if (
          this.inputOffer != null &&
          this.inputOffer != undefined &&
          this.inputOffer != ""
        ) {
          if (!app.isBuying) {
            app.isBuying = true;
            const activeAccount =
              await app.userStore.beacon.client.getActiveAccount();
            if (activeAccount) {
              try {
                console.log(
                  "Creating contract instance at " +
                    process.env.VUE_APP_MARKETPLACE_ADDRESS
                );
                const contract = await app.userStore.Tezos.wallet.at(
                  process.env.VUE_APP_MARKETPLACE_ADDRESS
                );

                console.log("Starting minting request..");
                console.log("Account is:", activeAccount);

                const shares = app.item?.royalties.map((el) => ({
                  amount: parseFloat(el.amount),
                  recipient: el.receiver_address,
                }));

                const val = parseFloat(this.inputOffer);
                console.log(val);
                const offerPrice = val * 1000000;

                const minting = await contract.methodsObject
                  .offer({
                    token: {
                      address:
                        app.item.listing == "cross"
                          ? this.contractAddressCross
                          : this.contractAddress11,
                      token_id: app.item.token_id,
                    },
                    currency: { tez: offerPrice },
                    amount: offerPrice,
                    shares,
                  })
                  .send({ amount: offerPrice / 1000000 });

                await minting.confirmation();
                app.$buefy.snackbar.open(
                  `Nft minted correctly, now transfer to collection.`
                );
                app.mintingState = "Transferring, please wait..";
                app.$buefy.snackbar.open(`Offer sent!`);
                app.isBuying = false;
                app.mintingState = "Buying nft, please wait..";
              } catch (error) {
                console.log(error);
                app.isBuying = false;
              }
            } else {
              app.$buefy.snackbar.open(`Can't get active account, unsyncing.`);
              app.unsync();
            }
          }
        }
      }
    },

    getAddress() {
      const app = this;
      if (app.item.creators.length > 1) {
        const filteredArray = app.item.creators.filter(
          (obj) => obj.holder.alias !== "Fakewhale | CROSS"
        );
        app.addressCreator = filteredArray.find(
          (obj) => obj.creator_address
        ).creator_address;
      } else {
        app.addressCreator = app.item.creators.find(
          (obj) => obj.creator_address
        ).creator_address;
      }

      /* console.log(app.addressCreator); */
    },
  },
  mounted() {
    this.getAddress();
  },
};
</script>

<style>
.audio-control {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: 30px;
}
.item-modal-root {
  margin-top: 60px;
}

.item-modal-back-btn {
  background: none;
  border: none;
  color: white;
  text-decoration: underline;
  display: block;
  margin-left: auto;
  cursor: pointer;
  font-size: 0.9rem;
}

.item-modal-waiting-box {
  min-height: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-modal-bottom-sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item-modal-bottom-sec-cost {
  font-size: 1.2rem;
}

.item-modal-read-more {
  background: transparent;
  color: white;
  border: none;
  text-decoration: underline;
}

.current-offer-text {
  color: var(--my-grey);
  font-size: 11px;
}

.single-nft-objkt-link {
  font-size: var(--subtitle-size);
  font-family: sans-serif;
}

@media screen and (max-width: 768px) {
  .item-modal-root {
    margin-top: 10px;
  }

  .item-modal-bottom-sec {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .item-modal-buy-description {
    font-size: 12px;
  }

  .item-modal-buy-inputs-single {
    width: 100%;
  }

  .item-modal-objkt-mobile {
    font-size: var(--subtitle-size);
  }

  .item-modal-buy-now-label {
    margin-top: 10px;
    color: var(--my-grey);
    font-size: 10px;
  }
}

.item-modal-buttons-box {
  width: 100%;
}

.item-modal-buy-title {
  font-size: 1.5rem;
}
/* .item-modal-buy-body {
} */

.item-modal-buy-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
}

.item-modal-buy-inputs-single {
  max-width: 500px;
}

.item-modal-buy-input-button {
  width: 100%;
  min-width: unset;
}

.item-modal-buy-input-button.mobile {
  width: 50%;
}

/* .item-modal-buy-input-field {
  width: 30%;
} */

.item-modal-purchase-buttons-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.item-modal-img-box,
.item-modal-video-box {
  position: relative;
}

.item-modal-img-filter {
  background: black;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity ease-in-out 0.2s;
  pointer-events: none;
}

.item-modal-img-filter::after {
  content: "See more";
  font-size: 20px;
  font-family: sans-serif;
}

.item-modal-video-box:hover > .item-modal-img-filter,
.item-modal-img-box:hover > .item-modal-img-filter {
  opacity: 0.7;
}

.item-modal-img {
  object-position: center;
  object-fit: contain;
  cursor: zoom-in;
}

.item-modal-video {
  cursor: zoom-in;
}

.item-modal-artist {
  font-size: 15px;
  text-align: left;
}
.item-modal-sub {
  font-size: var(--subtitle-size);
  color: var(--my-grey);
  text-align: left;
}

.item-modal-desc {
  font-size: var(--subtitle-size);
  text-align: left;
}

.item-modal-focus-ctn {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
}
.item-modal-focus-lazy {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
}
.item-modal-focus-background {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
}
.item-modal-focus-img {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
.item-modal-focus-video {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-modal-focus-video > video {
  width: 100%;
  height: 100%;
}

.item-modal-focus-close {
  z-index: 1;
  right: -3rem;
  position: absolute;
  top: 1rem;
}

@media screen and (max-width: 768px) {
  .item-modal-focus-img {
    max-width: 90vw;
    max-height: 70vh;
    width: 90vw;
    height: 70vh;
  }

  .item-modal-focus-video {
    max-width: 90vw;
    max-height: 70vh;
    width: 90vw;
    height: 70vh;
  }

  .item-modal-focus-close {
    right: 0;
    position: absolute;
    top: 2rem;
  }
}
</style>
