<template>
  <div v-if="isOpen" class="my-modal-root">
    <div class="my-modal-ctn">
      <div @click="onBackgroundClick" class="my-modal-background" />
      <div class="my-modal-box" :class="modalBoxCustomClass">
        <close-button class="my-modal-close" @closeClicked="onCloseClick" />
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import checkViewport from "../mixins/checkViewport.js";
import CloseButton from "./CloseButton.vue";
export default {
  components: { CloseButton },
  name: "MyModal",
  mixins: [checkViewport],
  props: ["isOpen", "modalBoxCustomClass"],
  data() {
    return {};
  },
  watch: {
    isOpen(newVal) {
      document
        .getElementsByClassName("nav")[0]
        .classList.toggle("blur-content", newVal);
      if (!this.isMobile) {
        document
          .getElementsByClassName("footer")[0]
          .classList.toggle("blur-content", newVal);
      } else {
        document
          .getElementsByClassName("footer-mobile")[0]
          .classList.toggle("blur-content", newVal);
      }
    },
  },
  methods: {
    onCloseClick() {
      this.$emit("closeClicked");
    },
    onBackgroundClick() {
      this.$emit("backgroundClicked");
    },
  },
};
</script>

<style>
.my-modal-root {
  margin-top: 80px;
}

.my-modal-ctn {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-modal-background {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.328);
  backdrop-filter: blur(4px);
}

.my-modal-box {
  z-index: 999;
  background: black;
  padding: 4rem;
  border-radius: 4px;
  border: 1px solid #545454;
  width: 60%;
  min-width: 250px;
  position: relative;
}

.my-modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media screen and (max-width: 768px) {
  .my-modal-box {
    padding: 4rem 2rem;
  }
}
</style>