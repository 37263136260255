import Buefy from "buefy";
import "buefy/dist/buefy.css";
import Vue from "vue";
import VueLazyLoadVideo from "vue-lazyload-video";
import { VImg } from "vuetensils/src/components";
import App from "./App.vue";
import "./assets/style.css";
import router from "./router";
import "./fonts/stylesheet.css";


import { createPinia, PiniaVuePlugin } from "pinia";
import LazyComponent from "v-lazy-component";

Vue.use(LazyComponent);

Vue.use(Buefy);
Vue.config.productionTip = false;
Vue.component("VImg", VImg);
Vue.use(VueLazyLoadVideo);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount("#app");
