<template>
  <div>
    <div class="marketplace-box">
      <div class="rows">
        <div :class="isMobile ? 'mb-5' : 'mb-6'" class="row spacer-header">
          <div class="columns marketplace-dots-row">
            <div class="column is-8 is-offset-2  testata">
              <p class="secondary-font">Curated Art Sales.</p>
            </div>
          </div>
          <!-- <div class="columns marketplace-dots-row">
            <div class="column is-1">
              <div
                class="marketplace-dot left"
                :class="!isMobile ? '' : ' mobile'"
              ></div>
            </div>
            <div class="column is-10">
              <div class="marketplace-dot centered"></div>
            </div>
            <div class="column is-1">
              <div
                class="marketplace-dot"
                :class="!isMobile ? 'centered' : 'right mobile'"
              ></div>
            </div>
          </div> -->
        </div>
        <div class="row mb-4" v-if="isMobile && nftsFetchStatus == 'success'">
          <button
            v-if="!filtersOpen"
            @click="filtersOpen = true"
            class="filter-toggle"
          >
            <img src="/filter.svg" />
            <span class="ml-1 mb-1">Filters</span>
          </button>
          <div v-if="filtersOpen" class="marketplace-filter-open-ctn px-3">
            <span class="secondary-font">Filters</span>
            <icon-button @iconClicked="filtersOpen = false"
              ><img src="/close.svg"
            /></icon-button>
          </div>
        </div>
        <div class="row">
          <div class="columns">
            <div
              v-if="
                nftsFetchStatus == 'pending' || artistsFetchStatus == 'pending'
              "
              class="marketplace-centered-ctn"
            >
              <div>Reading blockchain, please wait..</div>
            </div>
            <div v-else-if="nftsFetchStatus == 'failure'">
              {{ error }}
            </div>
            <div
              v-if="(!isMobile || filtersOpen) && nftsFetchStatus == 'success'"
              class="column is-2 marketplace-left-column"
            >
              <div class="marketplace-left-column-sticked pb-2">
                <div class="artworks-stats-ctn">
                  <h6 class="has-text-left secondary-font mb-4">Artworks</h6>
                </div>
                <div class="marketplace-dot left"></div>

                <filters
                  :listing="listing"
                  @filterSelected="onFilterChange"
                  :inputActiveFilters="activeFilters"
                />
              </div>
            </div>
            <div
              :class="
                filtersOpen || nftsFetchStatus != 'success' ? 'no-display' : ''
              "
              class="column is-10"
            >
              <div v-if="filteredListing.length > 0">
                <!-- <div class="mb-5 mr-5 has-text-right">
                  <my-button @clicked="onSyncClick">SYNC</my-button>
                </div> -->
                <div
                  class="columns is-multiline marketplace-nfts-ctn"
                  :class="isMobile ? '' : 'p-5'"
                >
                  <listing-card
                    class="marketplace-listing-card"
                    v-for="listingItem in paginatedListing"
                    :key="listingItem.objktUrl"
                    :listingItem="listingItem"
                    @cardClicked="onSelectNft"
                  >
                  </listing-card>
                </div>
                <div class="marketplace-pagination-ctn px-5 mt-6">
                  <icon-button
                    @iconClicked="paginationBack"
                    class="marketplace-pagination-left"
                    :class="
                      canMovePaginationBack
                        ? ''
                        : 'marketplace-disabled-pagination'
                    "
                  >
                    <img src="/arrow.svg" />
                  </icon-button>
                  <icon-button
                    @iconClicked="paginationForward"
                    :class="
                      canMovePaginationForward
                        ? ''
                        : 'marketplace-disabled-pagination'
                    "
                  >
                    <img src="/arrow.svg" />
                  </icon-button>
                </div>
              </div>
              <div
                v-else-if="
                  nftsFetchStatus == 'success' || nftsFetchStatus == 'not_sent'
                "
                style="text-align: left !important"
              >
                No NFTs available...
              </div>
            </div>
          </div>
        </div>
        <div
          :class="filtersOpen ? 'no-display' : ''"
          class="row mt-6 pt-6 spacer-footer"
        >
          <!-- <div class="columns marketplace-dots-row">
            <div class="column is-1">
              <div
                class="marketplace-dot left"
                :class="!isMobile ? '' : 'mobile'"
              ></div>
            </div>
            <div class="column is-10">
              <div class="marketplace-dot centered"></div>
            </div>
            <div class="column is-1">
              <div
                class="marketplace-dot"
                :class="!isMobile ? 'centered' : 'right mobile'"
              ></div>
            </div>
          </div> -->
        </div>
        <!-- FAKELOG START -->
        <!-- <div
          :class="filtersOpen ? 'no-display' : ''"
          class="row pt-6 marketplace-higlights-row"
        >
          <h6 class="secondary-font has-text-left pl-3">fakelog</h6>
          <div class="items scrollbar-semi-transparent">
            <div
              class="item marketplace-item"
              v-for="(highlight, index) in filteredListing.slice(0, 10)"
              :key="index"
            >
              <LazyComponent>
                <img
                  :src="
                    highlight.token.artifact_uri.replace(
                      'ipfs://',
                      'https://ipfs.yomi.digital/ipfs/'
                    )
                  "
                  style="height: 300px"
                />
                <Loader slot="placeholder" />
              </LazyComponent>
              <div class="mt-5"><a>Show more</a></div>
            </div>
          </div>
        </div> -->
        <!-- FAKELOG END -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Filters from "../components/Filters.vue";
import ListingCard from "../components/ListingCard.vue";
// import MyButton from "../components/MyButton.vue";
import IconButton from "../components/IconButton.vue";
import checkViewport from "../mixins/checkViewport.js";
export default {
  name: "Marketplace",
  components: {
    ListingCard,
    Filters,
    // MyButton,
    // LazyComponent,
    // Loader,
    IconButton,
  },
  mixins: [checkViewport],
  data() {
    return {
      listing: [],
      artists: [],
      // highlights: [],
      filteredListing: [],
      currentPage: 0,
      itemsPerPage: 9,
      filtersOpen: false,
      activeFilters: {},
      nftsFetchStatus: "not_sent",
      artistsFetchStatus: "not_sent",
      // highlightsFetchStatus: "not_sent",
      objktApi: process.env.VUE_APP_OBJKT_API,
      error: false,
      lambdaURL: process.env.VUE_APP_LAMBDA_URL,
      contractAddress11: process.env.VUE_APP_11_CONTRACT_ADDRESS,
      contractAddressCross: process.env.VUE_APP_CROSS_CONTRACT_ADDRESS,
    };
  },
  computed: {
    paginatedListing() {
      return this.filteredListing.slice(
        this.currentPage * this.itemsPerPage,
        (this.currentPage + 1) * this.itemsPerPage
      );
    },
    canMovePaginationForward() {
      return (
        this.currentPage <
        Math.ceil(this.filteredListing.length / this.itemsPerPage) - 1
      );
    },
    canMovePaginationBack() {
      return this.currentPage > 0;
    },
  },

  async mounted() {
    const app = this;
    if (this.isMobile) {
      this.itemsPerPage = 8;
    }

    await app.fetchArtists();
    await app.fetchNfts();

    const storedPagination = sessionStorage.getItem("fwpg");
    if (storedPagination != null) {
      this.currentPage = parseInt(atob(storedPagination));
      sessionStorage.removeItem("fwpg");
    }

    const storedFilters = sessionStorage.getItem("fwflt");
    if (storedFilters?.length > 0) {
      this.activeFilters = JSON.parse(atob(storedFilters));
      sessionStorage.removeItem("fwflt");
    }

    app.filterNfts();

    // const storedPos = sessionStorage.getItem("fwps");
    // if (storedPos != null) {
    //   setTimeout(() => {
    //     console.log(parseFloat(atob(storedPos)));
    //     window.scrollTo({
    //       top: parseFloat(atob(storedPos)),
    //     });
    //     sessionStorage.removeItem("fwps");
    //   }, 150);
    // }
  },
  methods: {
    paginationForward() {
      if (
        this.currentPage <
        Math.ceil(this.filteredListing.length / this.itemsPerPage) - 1
      )
        this.currentPage = this.currentPage + 1;

      window.scrollTo({ top: 0, behavior: "auto" });
    },
    paginationBack() {
      if (this.currentPage > 0) this.currentPage = this.currentPage - 1;
      window.scrollTo({ top: 0, behavior: "auto" });
    },
    async onSyncClick() {
      this.filteredListing = [];
      this.listing = [];
      await this.fetchNfts();
    },
    async fetchNfts() {
      const app = this;
      try {
        app.nftsFetchStatus = "pending";
        const listing11 = await axios.post(
          app.lambdaURL + "/marketplace/tokens",
          { contract: app.contractAddress11 }
        );

        if (!listing11.data.error) {
          const listingCross = await axios.post(
            app.lambdaURL + "/marketplace/tokens",
            { contract: app.contractAddressCross }
          );

          if (!listingCross.data.error) {
            app.listing = [
              ...listing11.data.tokens
                .filter((el) => el.supply > 0)
                .map((el) => {
                  const artistIndex =
                    el.creators?.[0]?.creator_address.toLowerCase() ==
                    process.env.VUE_APP_FW_CROSS_ADDRESS.toLowerCase()
                      ? 1
                      : 0;

                  const artist = this.artists.find(
                    (singleArtist) =>
                      singleArtist?.artistId?.toLowerCase() ==
                      el.creators?.[artistIndex]?.creator_address.toLowerCase()
                  );

                  const currentListings = el.listings.filter(
                    (singleListing) => singleListing.amount_left > 0
                  );

                  return {
                    ...el,
                    listing: "one-to-one",
                    currentListings,
                    contractAddress: app.contractAddress11,
                    objktUrl:
                      "https://objkt.com/asset/" +
                      app.contractAddress11 +
                      "/" +
                      el.token_id,
                    artistName:
                      artist != undefined
                        ? artist.name
                        : el.creators?.[0]?.holder?.tzdomain,
                  };
                }),
              ...listingCross.data.tokens
                .filter((el) => el.supply > 0)
                .map((el) => {
                  const artistIndex =
                    el.creators?.[0]?.creator_address.toLowerCase() ==
                    process.env.VUE_APP_FW_CROSS_ADDRESS.toLowerCase()
                      ? 1
                      : 0;

                  const artist = this.artists.find(
                    (singleArtist) =>
                      singleArtist?.artistId?.toLowerCase() ==
                      el.creators?.[artistIndex]?.creator_address.toLowerCase()
                  );

                  const currentListings = [...el.listings]
                    .filter((singleListing) => singleListing.amount_left > 0)
                    .sort((a, b) => a.price_xtz - b.price_xtz);

                  return {
                    ...el,
                    listing: "cross",
                    currentListings,
                    contractAddress: app.contractAddressCross,
                    objktUrl:
                      "https://objkt.com/asset/" +
                      app.contractAddressCross +
                      "/" +
                      el.token_id,
                    artistName:
                      artist != undefined
                        ? artist.name
                        : el.creators?.[0]?.holder?.tzdomain,
                  };
                }),
            ];

            app.listing.sort((a, b) => {
              const aTime = new Date(a.timestamp);
              const bTime = new Date(b.timestamp);

              return bTime.getTime() - aTime.getTime();
            });
            app.filterNfts();
            app.nftsFetchStatus = "success";
          } else {
            app.error = listingCross.data.message;
            app.nftsFetchStatus = "failure";
          }
        } else {
          app.error = listing11.data.message;
          app.nftsFetchStatus = "failure";
        }
        console.log(listing11.data);
        console.log("ff", app.listingCross);
      } catch (err) {
        app.error = err?.message ?? err;
        app.nftsFetchStatus = "failure";
      }
    },
    async fetchArtists() {
      const app = this;
      try {
        app.artistsFetchStatus = "pending";
        const artists = await axios.get(app.lambdaURL + "/artists");
        console.log("000", artists);
        if (!artists.data.error) {
          this.artists = artists.data.artists;
          app.artistsFetchStatus = "success";
        } else {
          app.error = artists.data.message;
          app.artistsFetchStatus = "failure";
        }
      } catch (err) {
        app.error = err?.message ?? err;
        app.artistsFetchStatus = "failure";
      }
    },
    onSelectNft() {
      if (this.activeFilters != {}) {
        sessionStorage.setItem(
          "fwflt",
          btoa(JSON.stringify(this.activeFilters))
        );
        sessionStorage.setItem("fwpg", btoa(this.currentPage));
        // sessionStorage.setItem("fwps", btoa(window.scrollY));
      }
    },
    // async fetchHighlights() {
    //   const app = this;
    //   try {
    //     app.nftsFetchStatus = "pending";
    //     const listingCross = await axios.post(
    //         app.lambdaURL + "/marketplace/tokens",
    //         { contract: app.contractAddressCross }
    //       );

    //       if (!listingCross.data.error) {
    //         app.listing = [
    //           ...listing11.data.listings.map((el) => ({
    //             ...el,
    //             listing: "one-to-one",
    //             objktUrl:
    //               "https://objkt.com/asset/" +
    //               app.contractAddress11 +
    //               "/" +
    //               el.id,
    //           })),
    //           ...listingCross.data.listings.map((el) => ({
    //             ...el,
    //             listing: "cross",
    //             objktUrl:
    //               "https://objkt.com/asset/" +
    //               app.contractAddressCross +
    //               "/" +
    //               el.id,
    //           })),
    //         ];
    //         app.filterNfts();
    //         app.nftsFetchStatus = "success";
    //       } else {
    //         app.error = listingCross.data.message;
    //         app.nftsFetchStatus = "failure";
    //       }
    //   } catch (err) {
    //     app.error = err?.message ?? err;
    //     app.nftsFetchStatus = "failure";
    //   }
    // },
    filterNfts() {
      this.filteredListing = this.listing.filter((singleItem) => {
        let collectionFilter = undefined;
        let marketFilter = undefined;
        let artistFilter = undefined;

        const filtersKeys = Object.keys(this.activeFilters);

        for (let i = 0; i < filtersKeys.length; i++) {
          const singleFilterKey = filtersKeys[i];

          if (singleFilterKey == "collection") {
            if (collectionFilter == undefined || collectionFilter == false) {
              if (
                this.activeFilters?.[singleFilterKey]?.[singleItem?.["listing"]]
              )
                collectionFilter = true;
              else collectionFilter = false;
            }
          } else if (singleFilterKey == "artist") {
            if (artistFilter == undefined || artistFilter == false) {
              if (
                this.activeFilters?.[singleFilterKey]?.[singleItem?.artistName]
              )
                artistFilter = true;
              else artistFilter = false;
            }
          } else if (singleFilterKey == "market") {
            if (singleItem.currentListings.length > 0) {
              if (marketFilter == undefined || marketFilter == false) {
                const itemMarket =
                  singleItem?.currentListings?.[0].seller_address?.toLowerCase() ==
                  process.env.VUE_APP_FW_CROSS_ADDRESS.toLowerCase()
                    ? "primary"
                    : "secondary";

                if (this.activeFilters?.[singleFilterKey]?.[itemMarket])
                  marketFilter = true;
                else marketFilter = false;
              }
            } else marketFilter = false;
          }
        }

        return (
          (collectionFilter == undefined ? true : collectionFilter) &&
          (artistFilter == undefined ? true : artistFilter) &&
          (marketFilter == undefined ? true : marketFilter)
        );
      });
    },
    onFilterChange(newFilters) {
      this.activeFilters = newFilters;
      this.filterNfts();
      this.currentPage = 0;
    },
  },
};
</script>

<style>
.block-scroll {
  overflow: hidden;
}

.artworks-stats-ctn {
  padding: 2rem 1rem 1rem;
}

.artworks-stats-ctn > h6 {
  font-size: 16px;
}

.marketplace-higlights-row {
  min-height: 40rem;
}

.marketplace-higlights-row .marketplace-item a {
  font-size: 11px;
}

.marketplace-left-column {
  border-right: 1px solid #333333;
}
.marketplace-left-column-sticked {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

.marketplace-hidden {
  opacity: 0;
  pointer-events: none;
}

.marketplace-nfts-ctn {
  height: 1700px;
  overflow-y: auto;
}

.marketplace-centered-ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 360px);
  width: 100%;
}

.marketplace-sync-button {
  min-width: unset;
  width: 50%;
  margin-left: 2.5px;
  margin-right: auto;
  display: block;
}

.marketplace-dot {
  width: 5px;
  height: 5px;
  background-color: white;
  display: block;
}

.marketplace-dot.centered {
  margin: auto;
}
.marketplace-dot.left {
  margin-left: 3rem;
}
.marketplace-dot.left.mobile {
  margin-left: 1rem;
}
.marketplace-dot.right.mobile {
  margin-left: auto;
  margin-right: 1rem;
}

.marketplace-pagination-ctn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.marketplace-pagination-left > img {
  transform: scaleX(-1);
}

.marketplace-disabled-pagination {
  opacity: 0.5;
  cursor: default;
}

.marketplace-filter-open-ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-toggle {
  background: transparent;
  color: white;
  border: none;
  height: 30px;
  display: flex;
  align-items: center;
}
.filter-toggle:disabled {
  opacity: 0.5;
}
.filter-toggle img {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 768px) {
  .marketplace-nfts-ctn {
    height: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-basis: 1;
  }

  .marketplace-centered-ctn {
    height: calc(100vh - 320px);
  }

  .marketplace-nfts-ctn .marketplace-listing-card {
    width: 100%;
    flex-basis: auto;
  }

  .marketplace-left-column {
    border-right: none;
  }

  .marketplace-dots-row {
    display: flex;
  }
}
</style>
