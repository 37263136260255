var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-4",on:{"click":_vm.onCardClick}},[_c('div',{staticClass:"listing-card-ctn",class:{'mb-4' : _vm.isMobile}},[_c('a',{attrs:{"href":'/#/cross/' + _vm.listingItem.contractAddress + '/' + _vm.listingItem.token_id}},[_c('LazyComponent',{staticClass:"listing-card-lazy"},[(_vm.listingItem.mime.startsWith('audio') && !_vm.isMobile)?_c('div',{staticClass:"listing-card-image-desk is-relative",class:_vm.loadedMedia ? '' : 'listing-card-not-loaded',style:({
            backgroundImage:
              'url(' +
              _vm.listingItem.display_uri.replace(
                'ipfs://',
                'https://ipfs.yomi.digital/ipfs/'
              ) +
              ')',
          }),on:{"load":_vm.onLoadImg}}):(!_vm.listingItem.mime.startsWith('video') && _vm.isMobile)?_c('img',{staticClass:"listing-card-image-mob",class:_vm.loadedMedia ? '' : 'listing-card-not-loaded',attrs:{"src":_vm.listingItem.display_uri.replace(
              'ipfs://',
              'https://ipfs.yomi.digital/ipfs/'
            )},on:{"load":_vm.onLoadImg}}):(!_vm.listingItem.mime.startsWith('video') && !_vm.isMobile)?_c('div',{staticClass:"listing-card-image-desk",class:_vm.loadedMedia ? '' : 'listing-card-not-loaded',style:({
            backgroundImage:
              'url(' +
              _vm.listingItem.artifact_uri.replace(
                'ipfs://',
                'https://ipfs.yomi.digital/ipfs/'
              ) +
              ')',
          }),on:{"load":_vm.onLoadImg}}):(!_vm.listingItem.mime.startsWith('video') && _vm.isMobile)?_c('img',{staticClass:"listing-card-image-mob",class:_vm.loadedMedia ? '' : 'listing-card-not-loaded',attrs:{"src":_vm.listingItem.artifact_uri.replace(
              'ipfs://',
              'https://ipfs.yomi.digital/ipfs/'
            )},on:{"load":_vm.onLoadImg}}):_c('video',{class:{
            'listing-card-image-mob': _vm.isMobile,
            'listing-card-image-desk': !_vm.isMobile,
            'listing-card-not-loaded': !_vm.loadedMedia,
          },attrs:{"preload":"metadata","autoplay":"","playsinline":"","loop":"","muted":""},domProps:{"muted":true},on:{"loadeddata":_vm.onLoadVideo}},[_c('source',{attrs:{"src":_vm.listingItem.artifact_uri.replace(
                'ipfs://',
                'https://ipfs.yomi.digital/ipfs/'
              )}})]),_c('Loader',{attrs:{"slot":"placeholder"},slot:"placeholder"})],1),_c('div',{staticClass:"listing-card-chips-ctn",class:!_vm.isMobile ? 'mt-5 pt-2 mb-2' : 'mt-4'},[(_vm.listingItem && _vm.listingItem.featured)?_c('div',{staticClass:"listing-card-chip px-3 py-1"},[_vm._v(" Featured ")]):_vm._e(),_c('div',{staticClass:"listing-card-chip py-1",class:!_vm.isMobile ? 'px-3 ' : 'px-3 mb-1'},[_vm._v(" "+_vm._s(_vm.listingItem.listing == "one-to-one" ? "Objkt 1.1" : "Objkt Ed.")+" ")])]),_c('a',{staticClass:"listing-card-artist artist-name mt-1 mb-1",attrs:{"href":'/#/artist/' + this.addressCreator}},[_vm._v(" "+_vm._s(_vm.listingItem.artistName)+" ")]),_c('div',{staticClass:"listing-card-name secondary-font",class:!_vm.isMobile ? 'mb-3 ' : 'mb-1 mt-2'},[_vm._v(" "+_vm._s(_vm.listingItem.name)+" ")]),(_vm.listingItem.currentListings.length > 0)?_c('div',{staticClass:"listing-card-price secondary-font"},[_vm._v(" "+_vm._s(_vm.listingItem.currentListings[0].price_xtz / 1000000)+" XTZ ")]):_c('div',{staticClass:"listing-card-price secondary-font"},[_vm._v("NOT LISTED")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }