<template>
  <div style="margin-top: 80px">
    <div :class="isMobile ? 'mb-5' : 'mb-6'" class="row  spacer-header">
      <!-- <div class="columns marketplace-dots-row">
        <div class="column is-1">
          <div
            class="marketplace-dot left"
            :class="!isMobile ? '' : ' mobile'"
          ></div>
        </div>
        <div class="column is-10">
          <div class="marketplace-dot centered"></div>
        </div>
        <div class="column is-1">
          <div
            class="marketplace-dot"
            :class="!isMobile ? 'centered' : 'right mobile'"
          ></div>
        </div>
      </div> -->
    </div>
    <div
      :class="{ 'pl-6 pr-6': !isMobile }"
      class="columns is-mobile is-multiline is-align-items-center mb-6 pb-5"
    >
      <div class="column is-6-mobile is-4-tablet is-3-desktop   has-text-left">
        <a style="border-bottom: none" class="is-inline-block" href="/#/vault">
          <img class="title-section" src="/vault.png" alt="" />
        </a>
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-flex is-align-items-center">
        | {{ printedAllArtistVault.length }} ARTISTS
      </div>
     
      <div :class="isMobile ? 'is-justify-content-start pt-0' : 'is-justify-content-end'" class="column is-12-mobile is-4-tablet is-6-desktop is-flex filter-artist is-align-items-baseline  is-relative">
        <p v-if="artistVaultSelectedCarat !== 'all' && artistVaultSelectedCarat !== '' && !isMobile" @click="artistVaultSelectedCarat = ''" class="remove">Remove Filter</p>
        <ul>
          <li @click="showListVault = !showListVault" style="cursor: pointer; font-size: 0.9rem;">
            A - Z

            <i
              :class="!showListVault ? 'fa-chevron-down' : 'fa-chevron-up'"
              class="fa-solid"
            ></i>
            <ul class="list-alphabet" v-if="showListVault">
              <li
                v-for="(letter, index) in alphabet"
                :key="index"
                @click="artistVaultSelectedCarat = letter.characterVault"
              >
                <span :class="letter.isOnVault ? 'active' : null">{{
                  letter.characterVault
                }}</span>
                -
              </li>
            </ul>
          </li>
        </ul>
        <p v-if="artistVaultSelectedCarat !== 'all' && artistVaultSelectedCarat !== '' && isMobile" @click="artistVaultSelectedCarat = ''" class="remove">Remove Filter</p>

      </div>
      
    </div>
    <div
      :class="{ 'pl-6 pr-6': !isMobile }"
      class="columns is-mobile is-multiline"
    >
      <div
        v-for="artist in printedAllArtistVault.slice(
          0,
          showMoreVault ? undefined : 32
        )"
        v-bind:key="artist.artistId"
        :class="{'pb-1' : isMobile}"
        class="column is-6-mobile is-4-tablet is-3-desktop name-artist"
      >
        <a
          v-if="artist.name !== 'turinglabs'"
          :href="'/#/artist/' + artist.artistId"
        >
          {{ artist.name }}
        </a>
      </div>
      <div class="column mt-5 is-12" v-if="printedAllArtistVault.length === 0">
        <p>No artists found.</p>
      </div>
      <div class="column mt-5 is-12" v-if="printedAllArtistVault.length > 20">
        <p @click="showMoreVault = !showMoreVault" class="has-text-left read">
          {{ showMoreVault ? "Read Less" : "Read More" }}
        </p>
      </div>
    </div>

    <!-- <div
      :class="{ 'pl-6 pr-6': !isMobile }"
      class="columns is-mobile is-multiline is-align-items-center mt-6 mb-6 pb-5 pt-6"
    >
      <div class="column is-6-mobile is-3-tablet is-3-desktop has-text-left">
        <a style="border-bottom: none" class="is-inline" href="/#/cross">
          <img class="title-section" src="/cross.png" alt="" />
        </a>
      </div>
      <div class="column is-6-mobile is-3-tablet is-3-desktop is-flex is-align-items-center">
        | {{ printedAllArtistCross.length }} ARTISTS
      </div>
      <div :class="isMobile ? 'is-justify-content-start pt-0' : 'is-justify-content-end'" class="column is-12-mobile is-6-tablet is-6-desktop is-flex filter-artist is-align-items-baseline is-relative">
        <p v-if="artistCrossSelectedCarat !== 'all' && artistCrossSelectedCarat !== '' && !isMobile" @click="artistCrossSelectedCarat = ''" class="remove">Remove Filter</p>

        <ul>
          <li @click="showListCross = !showListCross" style="cursor: pointer">
            A - Z

            <i
              :class="!showListCross ? 'fa-chevron-down' : 'fa-chevron-up'"
              class="fa-solid"
            ></i>
            <ul class="list-alphabet" v-if="showListCross">
              <li
                v-for="(letter, index) in alphabet"
                :key="index"
                @click="artistCrossSelectedCarat = letter.characterCross"
              >
                <span :class="letter.isOnCross ? 'active' : null">{{
                  letter.characterCross
                }}</span>
                -
              </li>
            </ul>
          </li>
        </ul>
        <p v-if="artistCrossSelectedCarat !== 'all' && artistCrossSelectedCarat !== '' && isMobile" @click="artistCrossSelectedCarat = ''" class="remove">Remove Filter</p>

      </div>
    </div>
    <div
      :class="{ 'pl-6 pr-6': !isMobile }"
      class="columns is-mobile is-multiline mb-6 pb-6"
    >
      <div
        v-for="artist in printedAllArtistCross.slice(
          0,
          showMoreCross ? undefined : 20
        )"
        v-bind:key="artist.artistId"
        class="column is-6-mobile is-4-tablet is-3-desktop name-artist"
      >
        <a
          v-if="artist.name !== 'turinglabs'"
          :href="'/#/artist/' + artist.artistId"
        >
          {{ artist.name }}
        </a>
      </div>
      <div class="column mt-5 is-12" v-if="printedAllArtistCross.length === 0">
        <p>No artists found.</p>
      </div>
      <div class="column mt-5 is-12" v-if="printedAllArtistCross.length > 20">
        <p @click="showMoreCross = !showMoreCross" class="has-text-left read">
          {{ showMoreCross ? "Read Less" : "Read More" }}
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import checkViewport from "../mixins/checkViewport.js";
export default {
  name: "Artists",
  props: ["listingItem"],
  mixins: [checkViewport],
  data() {
    return {
      artistsVault: [],
      artistsCross: [],
      axios: axios,
      showMoreVault: false,
      showMoreCross: false,
      showListVault: false,
      showListCross: false,
      artistVaultSelectedCarat: "all",
      artistCrossSelectedCarat: "all",
      alphabet: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
    };
  },
  computed: {
    printedAllArtistVault() {
      const app = this;
      if (app.artistVaultSelectedCarat !== "all") {
        return app.artistsVault.filter((artist) =>
          artist.name
            .toLowerCase()
            .startsWith(app.artistVaultSelectedCarat.toLowerCase())
        );
      } else {
        return app.artistsVault;
      }
    },
    printedAllArtistCross() {
      const app = this;
      if (app.artistCrossSelectedCarat !== "all") {
        return app.artistsCross.filter((artist) =>
          artist.name
            .toLowerCase()
            .startsWith(app.artistCrossSelectedCarat.toLowerCase())
        );
      } else {
        return app.artistsCross;
      }
    },
  },

  async mounted() {
    const app = this;
    await app.getArtistVault();
  },

  methods: {
    /* setActiveLetter(letter) {
      this.artistVaultSelectedCarat = letter;
      this.artistCrossSelectedCarat = letter;
      this.activeLetter = letter;
    }, */
    async getArtistVault() {
      const app = this;
      try {
        const allArtist = await app.axios.get(
          process.env.VUE_APP_LAMBDA_URL + "/artists"
        );
   
       
        const allArtistVault = allArtist.data.artists.filter(
          (artist) => (!artist.isHidden && artist.isCross || !artist.isHidden) && artist.name !== "TEST1" && artist.name !== "turinglabs"
        );
        const allArtistCross = allArtist.data.artists.filter(
          (artist) => artist.isCross && artist.isHidden
        );

        app.artistsVault = allArtistVault.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          return nameA.localeCompare(nameB);
        });
        /* app.artistsVault = allArtistVault.sort(
          (a, b) => b.tokenBase - a.tokenBase
        ); */
        
        app.artistsCross = allArtistCross.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          return nameA.localeCompare(nameB);
        });

        for (let i = 0; i < app.alphabet.length; i++) {
          const element = app.alphabet[i];
          /* console.log("element", element); */

          const isOnVault =
            allArtistVault.findIndex(
              (el) => el.name.toLowerCase()[0] === element
            ) !== -1;

          const isOnCross =
            allArtistCross.findIndex(
              (el) => el.name.toLowerCase()[0] === element
            ) !== -1;

          app.alphabet[i] = {
            characterVault: element,
            isOnVault,
            characterCross: element,
            isOnCross,
          };
        }
        /* console.log('===>',test);
        console.log('artistsVault',app.artistsVault); */
        /* console.log('artists',app.artists); */
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>

</style>
