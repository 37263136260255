<template>
  <div>
    <div class="single-nft-box">
      <div class="rows">
        <div class="columns marketplace-dots-row">
            <div class="column is-8 is-offset-2  testata">
              <p class="secondary-font">Curated Art Sales.</p>
            </div>
          </div>
        <div v-if="!isMobile" class="row">
          <!-- <div class="columns single-nft-dots-columns">
            <div class="column is-1">
              <div class="single-nft-dot left"></div>
            </div>
            <div class="column is-10">
              <div class="single-nft-dot centered"></div>
            </div>
            <div class="column is-1">
              <div class="single-nft-dot centered"></div>
            </div>
          </div> -->
        </div>
        <div
          v-if="nftFetchStatus == 'success' && selectedItem != false"
          class="row"
        >
          <div class="columns single-nft-item-container">
            <div class="column is-11">
              <item-modal
                @backClicked="onModalBackClick"
                :item="selectedItem"
              />
            </div>
          </div>
        </div>
        <div
          v-if="nftFetchStatus == 'pending' || artistsFetchStatus == 'pending'"
          class="single-nft-centered-ctn"
        >
          <div>Retrieving NFT, please wait..</div>
        </div>
        <div
          v-else-if="nftFetchStatus == 'failure'"
          style="text-align: left !important"
        >
          {{ error }}
        </div>
        <div class="row mt-6 pt-6 spacer-footer">
          <!-- <div class="columns single-nft-dots-columns">
            <div class="column is-1">
              <div
                class="single-nft-dot left"
                :class="!isMobile ? '' : 'mobile'"
              ></div>
            </div>
            <div class="column is-10">
              <div class="single-nft-dot centered"></div>
            </div>
            <div class="column is-1">
              <div
                class="single-nft-dot"
                :class="!isMobile ? 'centered' : 'right mobile'"
              ></div>
            </div>
          </div> -->
        </div>
        <!-- FAKELOG START -->
        <!-- <div class="row pt-6 single-nft-higlights-row">
          <h6 class="secondary-font has-text-left pl-3">fakelog</h6>
          <div v-if="highlightsFetchStatus == 'pending'">
            Retrieving posts, please wait..
          </div>
          <div v-else-if="highlightsFetchStatus == 'failure'">
            {{ highlightsError }}
          </div>
          <div
            v-else-if="highlightsFetchStatus == 'success'"
            class="items scrollbar-semi-transparent"
          >
            <div
              class="item single-nft-item"
              v-for="(highlight, index) in highlights.slice(0, 10)"
              :key="index"
            >
              <LazyComponent>
                <img
                  :src="
                    highlight.token.artifact_uri.replace(
                      'ipfs://',
                      'https://ipfs.yomi.digital/ipfs/'
                    )
                  "
                  style="height: 300px"
                />
                <Loader slot="placeholder" />
              </LazyComponent>
              <div class="mt-5"><a>Show more</a></div>
            </div>
          </div>
        </div> -->
        <!-- FAKELOG END -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ItemModal from "../components/ItemModal.vue";
// import MyButton from "../components/MyButton.vue";
import { gql, request } from "graphql-request";
import checkViewport from "../mixins/checkViewport.js";
export default {
  name: "SingleNft",
  components: {
    ItemModal,
  },
  mixins: [checkViewport],
  data() {
    return {
      selectedItem: false,
      artists: [],
      nftFetchStatus: "not_sent",
      artistsFetchStatus: "not_sent",
      highlightsFetchStatus: "not_sent",
      objktApi: process.env.VUE_APP_OBJKT_API,
      error: false,
      highlightsError: false,
      lambdaURL: process.env.VUE_APP_LAMBDA_URL,
      highlights: [],
    };
  },
  async mounted() {
    const app = this;

    await app.fetchArtists();
    await app.fetchNft();
    // await app.fetchHighlights();
  },
  methods: {
    async fetchNft() {
      const app = this;
      try {
        app.nftFetchStatus = "pending";
        const selectedListing = await axios.post(
          app.lambdaURL + "/marketplace/tokens",
          { contract: app.$route.params.contract }
        );
        if (!selectedListing.data.error) {
          const singleNftFiltered = [
            ...selectedListing.data.tokens
              .filter((el, singleItemIndex) => {
                if (
                  el?.token_id?.toString() ==
                  app.$route.params.tokenId.toString()
                ) {
                  return true;
                }
                return false;
              })
              .map((el) => ({
                ...el,
                contractAddress: app.$route.params.contract,
                objktUrl:
                  "https://objkt.com/asset/" +
                  app.$route.params.contract +
                  "/" +
                  el.token_id,
              })),
          ];

          if (singleNftFiltered?.length > 0) {
            const query = gql`
              query GetAdditionalData(
                $token_id: String
                $contract_address: String
              ) {
                token(
                  where: {
                    token_id: { _eq: $token_id }
                    fa_contract: { _eq: $contract_address }
                  }
                ) {
                  highest_offer
                  name
                  token_id
                  timestamp
                  display_uri
                  thumbnail_uri
                  events(
                    # limit: 15
                    order_by: { level: desc, id: desc }
                    where: {
                      timestamp: { _is_null: false }
                      reverted: { _neq: true }
                      _or: [
                        { event_type: { _is_null: true } }
                        { event_type: { _neq: "transfer" } }
                      ]
                    }
                  ) {
                    event_type
                    amount
                    price_xtz
                    marketplace_event_type
                    timestamp
                    level
                    recipient {
                      alias
                      address
                      tzdomain
                    }
                    creator {
                      alias
                      address
                      tzdomain
                    }
                  }
                }
              }
            `;
            const gqlRes = await request(app.objktApi, query, {
              token_id: app.$route.params.tokenId,
              contract_address: app.$route.params.contract,
            });

            let highestOffer = 0.0;
            let events = [];
            if (gqlRes.token && gqlRes.token?.length > 0) {
              if (gqlRes.token[0].highest_offer != null) {
                highestOffer = gqlRes.token[0].highest_offer / 1000000;
              }

              if (
                gqlRes.token[0]?.events != null &&
                gqlRes.token[0].events?.length > 0
              ) {
                events = gqlRes.token[0].events
                  .filter(
                    (el) =>
                      el?.marketplace_event_type != "list_cancel" &&
                      el?.marketplace_event_type != "offer_cancel"
                  )
                  .map((el) => ({
                    ...el,
                    date: moment(el.timestamp).format("DD/MM/YYYY HH:mm"),
                    price_xtz:
                      el.price_xtz !== null ? el.price_xtz / 1000000 : null,
                  }));
              }
            }

            const artistIndex =
              singleNftFiltered[0].creators?.[0]?.creator_address.toLowerCase() ==
              process.env.VUE_APP_FW_CROSS_ADDRESS.toLowerCase()
                ? 1
                : 0;

            const artist = this.artists.find(
              (singleArtist) =>
                singleArtist?.artistId?.toLowerCase() ==
                singleNftFiltered[0].creators?.[
                  artistIndex
                ]?.creator_address.toLowerCase()
            );

            const currentListings = [...singleNftFiltered[0].listings]
              .filter((singleListing) => singleListing.amount_left > 0)
              .sort((a, b) => a.price_xtz - b.price_xtz);

            this.selectedItem = {
              ...singleNftFiltered[0],
              currentListings,
              history: events,
              highestOffer,
              artistName:
                artist != undefined
                  ? artist.name
                  : singleNftFiltered[0].creators?.[0]?.holder?.tzdomain,
            };

            app.nftFetchStatus = "success";
          }
        } else {
          app.error = selectedListing.data.message;
          app.nftFetchStatus = "failure";
        }
      } catch (err) {
        console.log(err);
        app.error = err?.message ?? err;
        app.nftFetchStatus = "failure";
      }
    },
    async fetchArtists() {
      const app = this;
      try {
        app.artistsFetchStatus = "pending";
        const artists = await axios.get(app.lambdaURL + "/artists");

        if (!artists.data.error) {
          this.artists = artists.data.artists;
          app.artistsFetchStatus = "success";
        } else {
          app.error = artists.data.message;
          app.artistsFetchStatus = "failure";
        }
      } catch (err) {
        app.error = err?.message ?? err;
        app.artistsFetchStatus = "failure";
      }
    },
    async fetchHighlights() {
      const app = this;
      try {
        app.highlightsFetchStatus = "pending";
        const selectedListing = await axios.post(
          app.lambdaURL + "/marketplace/listings",
          { contract: "KT1CD3cTqBxwPKcihd27QDALLy351iSUKPhV" }
        );

        if (!selectedListing.data.highlightsError) {
          app.highlights = selectedListing.data.listings;
          app.highlightsFetchStatus = "success";
        } else {
          app.highlightsError = selectedListing.data.message;
          app.highlightsFetchStatus = "failure";
        }
      } catch (err) {
        app.highlightsError = err?.message ?? err;
        app.highlightsFetchStatus = "failure";
      }
    },
    onModalBackClick() {
      if (this.itemFocused) this.itemFocused = false;
      location.href = "/#/cross";
    },
  },
};
</script>

<style>
.block-scroll {
  overflow: hidden;
}

.single-nft-item-container {
  justify-content: center;
}

.single-nft-box {
  margin-top: 90px;
}

.single-nft-centered-ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 340px);
  width: 100%;
}

.artworks-stats-ctn {
  padding: 2rem 1rem 1rem;
}

.artworks-stats-ctn > h6 {
  font-size: 16px;
}

.single-nft-higlights-row {
  min-height: 40rem;
}

.single-nft-higlights-row .single-nft-item a {
  font-size: 11px;
}

.single-nft-left-column {
  border-right: 1px solid #333333;
}

.single-nft-hidden {
  opacity: 0;
  pointer-events: none;
}

.single-nft-sync-button {
  min-width: unset;
  width: 50%;
  margin-left: 2.5px;
  margin-right: auto;
  display: block;
}

.single-nft-dot {
  width: 5px;
  height: 5px;
  background-color: white;
  display: block;
}

.single-nft-dot.centered {
  margin: auto;
}
.single-nft-dot.left {
  margin-left: 3rem;
}
.single-nft-dot.left.mobile {
  margin-left: 1rem;
}
.single-nft-dot.right.mobile {
  margin-left: auto;
  margin-right: 1rem;
}

.single-nft-pagination-ctn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.single-nft-pagination-left > img {
  transform: scaleX(-1);
}

.single-nft-filter-open-ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-toggle {
  background: transparent;
  color: white;
  border: none;
  height: 30px;
  display: flex;
  align-items: center;
}
.filter-toggle:disabled {
  opacity: 0.5;
}
.filter-toggle img {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 768px) {
  .single-nft-box {
    margin-top: 130px;
  }

  .single-nft-centered-ctn {
    height: calc(100vh - 320px);
  }

  .single-nft-dots-columns {
    display: flex;
  }
}
</style>
