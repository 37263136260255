<template>
  <div class="container" style="margin-top: 70px">
    <div
      class="column"
      style="padding: 45vh 0; text-align: center"
      v-if="!loaded"
    >
      Reading blockchain,<br />
      please wait..
    </div>
    <div v-if="loaded" style="padding-bottom: 50px">
      <div v-for="(nft, index) in nfts" v-bind:key="nft.artifact_uri">
        <LazyComponent>
          <video
            style="height: 90vh"
            v-if="nft.mime.indexOf('video') !== -1"
            autoplay
            muted
            loop
            playsinline
          >
            <source :src="nft.artifact_uri" :type="nft.mime" />
            Your browser does not support HTML video.
          </video>
          <Loader slot="placeholder" />
        </LazyComponent>
        <div class="nft-details">
          {{ nft.name }}
          <div style="cursor: pointer" v-on:click="toggleHolders(index)">
            {{ nft.supply }} collected the cover
          </div>
        </div>
        <div class="holders" v-if="showHolders">
          <h1 class="title is-2">Collectors</h1>
          <div
            style="
              position: fixed;
              top: 15px;
              right: 15px;
              cursor: pointer;
              font-size: 30px;
            "
            v-on:click="showHolders = false"
          >
            X
          </div>
          <div v-for="holder in nft.holders" v-bind:key="holder.tzdomain">
            <span style="font-size: 22px" v-if="holder.holder.tzdomain"
              >{{ holder.holder.tzdomain }}<br
            /></span>
            <span style="font-size: 12px">{{ holder.holder_address }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { gql, request } from "graphql-request";
import LazyComponent from "v-lazy-component";

export default {
  name: "Covers",
  data() {
    return {
      nfts: [],
      showHolders: false,
      objktApi: process.env.VUE_APP_OBJKT_API,
      coversContract: process.env.VUE_APP_COVERS_CONTRACT,
      loaded: false,
    };
  },
  components: {
    LazyComponent,
    Loader,
  },
  async mounted() {
    const app = this;
    app.loaded = true;
    const query = gql`
      {
        token(
          where: {
            fa_contract: { _eq: "KT1WWvvdXaM72XdFU7j2f3DR9zaYkPZRNEoB" }
            supply: { _gt: "0" }
          }
        ) {
          artifact_uri
          description
          display_uri
          extra
          metadata
          mime
          name
          supply
          thumbnail_uri
          timestamp
          holders(
            where: {
              token: {
                fa_contract: { _eq: "KT1WWvvdXaM72XdFU7j2f3DR9zaYkPZRNEoB" }
              }
              quantity: { _gt: "0" }
            }
          ) {
            last_incremented_at
            quantity
            holder_address
            holder {
              tzdomain
            }
          }
        }
      }
    `;
    request(app.objktApi, query).then((data) => {
      for (let k in data.token) {
        data.token[k].artifact_uri = data.token[k].artifact_uri.replace(
          "ipfs://",
          "https://ipfs.yomi.digital/ipfs/"
        );
        data.token[k].display_uri = data.token[k].display_uri.replace(
          "ipfs://",
          "https://ipfs.yomi.digital/ipfs/"
        );
        data.token[k].thumbnail_uri = data.token[k].thumbnail_uri.replace(
          "ipfs://",
          "https://ipfs.yomi.digital/ipfs/"
        );
        app.nfts.push(data.token[k]);
      }
    });
  },
  methods: {
    toggleHolders() {
      const app = this;
      if (!app.showHolders) {
        app.showHolders = true;
      } else {
        app.showHolders = false;
      }
    },
  },
};
</script>
