<template>
  <div class="fw-loader" :class="isMobile ? 'mob' : 'desk'"></div>
</template>

<script>
import checkViewport from "../mixins/checkViewport.js";

export default {
  name: "Loader",
  mixins: [checkViewport],
};
</script>

<style scoped>
.fw-loader {
  height: 160px;
  width: 80px;
  background-image: url("/loading.gif");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: unset;
  margin: auto;
}
.fw-loader.mob {
  height: 80px;
}
</style>
