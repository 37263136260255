<template>
  <div class="filters-ctn">
    <!-- COLLECTION FILTER -->
    <filter-group
      v-for="(singleFilter, index) in generatedFilters"
      :key="index"
      :title="singleFilter.label"
      :options="singleFilter.values"
      :disabled="disabled"
      @filterClick="(key, value) => selectFilter(singleFilter.key, key, value)"
    />
    <!-- ARTIST FILTER -->
    <!-- <filter-group title="ARTISTS" :options="artistsTransformed" /> -->
  </div>
</template>

<script>
import FilterGroup from "./FilterGroup.vue";
export default {
  name: "Filters",
  props: ["listing", "disabled", "inputActiveFilters"],
  data() {
    return {
      generatedFilters: [
        {
          key: "collection",
          label: "Collection",
          values: [
            // {
            //   value: "featured",
            //   label: "Featured",
            //   key: "featured",
            //   checked: false,
            // },
            {
              value: "one-to-one",
              label: "Objkt 1:1",
              key: "one-to-one",
              checked: false,
            },
            {
              value: "cross",
              label: "Objkt Ed.",
              key: "cross",
              checked: false,
            },
          ],
        },
        {
          key: "market",
          label: "Market",
          values: [
            {
              value: "primary",
              label: "Primary",
              key: "primary",
              checked: false,
            },
            {
              value: "secondary",
              label: "Secondary",
              key: "secondary",
              checked: false,
            },
          ],
        },
        {
          key: "artist",
          label: "Artists",
          values: [],
        },
      ],
      activeFilters: {},
    };
  },
  components: {
    FilterGroup,
  },
  mounted() {
    this.generateFilters();
    if (this.inputActiveFilters != undefined) {
      if (this.inputActiveFilters) {
        this.activeFilters = this.inputActiveFilters;
        this.initFiltersValues();
      }
    }
  },
  watch: {
    listing() {
      this.generateFilters();
    },
    inputActiveFilters() {
      if (this.inputActiveFilters) {
        this.activeFilters = this.inputActiveFilters;
        this.initFiltersValues();
      }
    },
  },
  methods: {
    generateFilters() {
      if (this.listing && this.listing.length > 0) {
        for (let i = 0; i < this.listing.length; i++) {
          const element = this.listing[i];
          const artistName = element.artistName;
          if (
            artistName != null &&
            artistName != undefined &&
            artistName != "" &&
            this.generatedFilters[2].values.findIndex(
              (el) => el.value == artistName
            ) == -1
          ) {
            this.generatedFilters[2].values.push({
              key: artistName,
              label: artistName,
              value: artistName,
            });
          }
        }
      }
    },
    initFiltersValues() {
      const newFilters = [];
      for (let i = 0; i < this.generatedFilters.length; i++) {
        const singleFilter = this.generatedFilters[i];
        newFilters.push({
          ...singleFilter,
          values: [],
        });
        for (let j = 0; j < singleFilter.values.length; j++) {
          const singleValue = singleFilter.values[j];
          newFilters[i].values.push({
            ...singleValue,
            checked: this.activeFilters?.[singleFilter.key]?.[singleValue.key],
          });
        }
      }
      this.generatedFilters = newFilters;
    },
    selectFilter(filterKey, optionKey, optionValue) {
      if (!Object.keys(this.activeFilters).includes(filterKey)) {
        this.activeFilters[filterKey] = {};
      }
      if (optionValue) this.activeFilters[filterKey][optionKey] = optionValue;
      else delete this.activeFilters[filterKey][optionKey];
      if (Object.keys(this.activeFilters[filterKey]).length == 0)
        delete this.activeFilters[filterKey];
      this.$emit("filterSelected", this.activeFilters);
    },
  },
};
</script>

<style>
.filters-ctn {
  /* height: calc(100vh - 140px); */
  margin-right: 10px;
  padding: 1rem 1rem 2rem;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .filters-ctn {
    height: calc(100vh - 140px);
  }
}
</style>